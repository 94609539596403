import React, { useEffect, useState, useContext } from "react";
import { Container } from "react-bootstrap";
import cn from "classnames";
import SiteContext from "./SiteContext";

import Rounds from "./Rounds";

const Home = () => {
  const { api } = useContext(SiteContext);
  const [rounds, setRounds] = useState([]); // Initialize with empty array
  const [error, setError] = useState(null);

  useEffect(() => {
    api
      .getRounds({ range: "all" })
      .then(function (response) {
        setRounds(response.data || []); // Ensure we always set an array
      })
      .catch(function (error) {
        console.error(error);
        setError(error);
        setRounds([]); // Set empty array on error
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Add empty dependency array to run only on mount

  const isMobile = window.innerWidth < 768;

  return (
    <Container className={cn({ "pad-0": isMobile })}>
      {error ? (
        <div>Error loading rounds. Please try again later.</div>
      ) : (
        <Rounds data={rounds} />
      )}
    </Container>
  );
};

export default Home;
