import React, { useState, useMemo } from "react";

import { Link } from "react-router-dom";
import { Button, Container, Row, Col } from "react-bootstrap";
import { TablePlain as Table } from "./TablePlain";
import { dt, sortByProperties } from "./helpers";

const Rounds = ({ data = [], range }) => {
  const [showPastRounds, setShowPastRounds] = useState(true);

  const today = new Date();
  const now = today.getTime() / 1000;

  const pastRounds = useMemo(
    () =>
      Array.isArray(data)
        ? data
            .filter(r => r && r.d < now)
            .sort(sortByProperties([{ field: "d", asc: false }]))
        : [],
    [data, now]
  );

  const futureRounds = useMemo(
    () =>
      Array.isArray(data)
        ? data
            .filter(r => r && r.d > now)
            .sort(sortByProperties([{ field: "d", asc: true }]))
        : [],
    [data, now]
  );

  const isMobile = window.innerWidth < 768;

  return (
    <div className="content">
      {!data && "Loading Data..."}
      <Container fluid>
        {!!data?.length && (
          <>
            <Row className="mb-3">
              {isMobile && (
                <Button
                  variant="outline-primary"
                  size="sm"
                  onClick={() => setShowPastRounds(!showPastRounds)}
                >
                  Show {showPastRounds ? "Future" : "Past"} Rounds
                </Button>
              )}
            </Row>
            <Row>
              {(!isMobile || (isMobile && !!showPastRounds)) && (
                <Col>
                  <h3 className="title">Past Rounds</h3>
                  <Table>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Name</th>
                        <th>Location</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pastRounds.map(d => (
                        <tr key={d.d}>
                          <td>
                            <Link to={`/rounds/${d.id}`}>{dt(d.d)}</Link>
                          </td>
                          <td>{d.n}</td>
                          <td>{d.loc}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              )}
              {(!isMobile || (isMobile && !showPastRounds)) && (
                <Col>
                  <h3 className="title">Future Rounds</h3>
                  <Table>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Name</th>
                        <th>Location</th>
                      </tr>
                    </thead>
                    <tbody>
                      {futureRounds.map(d => (
                        <tr key={d.d}>
                          <td>
                            <Link to={`/rounds/${d.id}`}>{dt(d.d)}</Link>
                          </td>
                          <td>{d.n}</td>
                          <td>{d.loc}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              )}
            </Row>
          </>
        )}
      </Container>
    </div>
  );
};

export default Rounds;
