import React, { useState, useEffect, useContext } from "react";
// import * as api from "../services/api";
import { Link } from "react-router-dom";
import { TablePlain as Table } from "./TablePlain";
import { sortByProperties } from "./helpers";
import SiteContext from "./SiteContext";

const PlayersAll = () => {
  // const [state, setState] = useState({
  //   response: "",
  //   post: "",
  //   responseToPost: ""
  // });
  const { api } = useContext(SiteContext);

  const [data, setData] = useState();

  useEffect(() => {
    if (!data) {
      //var data = JSON.stringify({ name: "Mike" });

      api
        .getPlayers()
        .then(function (response) {
          setData(
            response.data.sort(sortByProperties([{ field: "fn", asc: true }]))
          );
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  });

  return (
    <div className="content">
      <h3>Players</h3>
      {!data && "Loading Data..."}
      {!!data?.length && (
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>PDGA</th>
              <th>Division</th>
              <th>Rounds</th>
              <th>{new Date().getFullYear()} Rounds</th>
              <th>Avg</th>
              <th>Current Avg</th>
              <th>Ballance</th>
            </tr>
          </thead>
          <tbody>
            {data.map(d => {
              return (
                <tr key={d.id}>
                  <td>
                    <Link to={`/players/${d.id}`}>
                      {d.fn} {d.ln}
                    </Link>
                  </td>
                  <td>{d.pdga}</td>
                  <td>{d.division}</td>
                  <td>{d.roundCount}</td>
                  <td>{d.roundCountYear}</td>
                  <td>{d.average}</td>
                  <td>{d.thisYearAverage}</td>
                  <td>{d.balance}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default PlayersAll;
