import { Table } from "react-bootstrap";

export const TablePlain = ({ children }) => {
  return (
    <div className="table-wrapper">
      <Table className="round__table" striped size="sm">
        {children}
      </Table>
    </div>
  );
};
