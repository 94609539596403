import "./Entries.scss";
import React, {
  useContext,
  useState
} from "react";
import { Button, Col, Form } from "react-bootstrap";
import SiteContext from "../SiteContext";

const User = () => {
  const { token, api } = useContext(SiteContext);

  const defaultValues = {
    u: "",
    p: ""
  };
  const [values, setValues] = useState(defaultValues);


  const handleOnChange = e => {
    setValues({ ...values, [e.target.id]: e.target.value });
  };

  const addUser = p => {
    api
      .addUser({
        values: {
         u: values.u, p: values.p
        },
        token
      })
      .then(results => {
      
        alert("done")
      });
  };



  return (
    <div className="content">
      
          <div id="FORM">
            <Form noValidate>
             
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>User</Form.Label>
                  <Form.Control
                    id="u"
                    value={values.u}
                    onChange={handleOnChange}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    id="p"
                    value={values.p}
                    onChange={handleOnChange}
                  />
                </Form.Group>
              </Form.Row>

              <Form.Group>
                <Button
                  onClick={addUser}
                  disabled={!values.u || !values.p}
                >
                  Update
                </Button>
              
              </Form.Group>
            </Form>
         
            </div>
        </div>
  );
};

export default User;
