import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import FormSetup from "./FormSetup";
import { arrayToOptions, divisions } from "./helpers";
import SiteContext from "./SiteContext";

const EditPlayerModal = ({ playerId, onCancel, onComplete }) => {
  const { token, api } = useContext(SiteContext);

  const [playerData, setPlayerData] = useState();

  useEffect(() => {
    if (!playerData)
      api.getPlayer({ playerId }).then(response => {
        if (response?.data) {
          setPlayerData(response?.data);
        }
      });
  }, [api, playerData, playerId]);

  const fieldData = [
    { name: "fn", label: "First", type: "text", required: true },
    { name: "ln", label: "Last", type: "text", required: true },
    { name: "pdga", label: "PDGA", type: "text", required: false },
    {
      name: "division",
      label: "Division",
      type: "select",
      required: true,
      options: arrayToOptions(divisions)
    },
    { name: "inactive", label: "Inactive", type: "checkbox" }
  ];

  const onUpdatePlayer = ({ values }) => {
    api
      .savePlayer({ values, token, id: playerId })
      .then(response => {
        if (response?.data?.success) onComplete({ playerId, values });
        else alert("error updating player");
      })
      .catch(() => alert("error updating player"));
  };

  if (!playerData) return null;

  return (
    <Modal show>
      <Modal.Header>
        <Modal.Title>Edit Player</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Link to={`/players/${playerId}`}>Go to Profile</Link>
        <FormSetup
          onSubmit={onUpdatePlayer}
          onCancel={onCancel}
          fields={fieldData.map(f => ({
            ...f,
            default: playerData[f.name]
          }))}
        />
      </Modal.Body>
    </Modal>
  );
};

export default EditPlayerModal;
