import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
//import * as api from "../services/api";
import SiteContext from "./SiteContext";
import FormSetup from "./FormSetup";
import { Form, Button, Modal } from "react-bootstrap";
import Table from "./Table";
import { currency } from "./helpers";
import axios from "axios";
import "./admin/Player.scss";

const fieldData = [
  { name: "fn", label: "First", type: "text", required: true },
  { name: "ln", label: "Last", type: "text", required: true },
  { name: "pdga", label: "PDGA", type: "text", required: true },
  { name: "division", label: "Division", type: "text", required: true },
  { name: "inactive", label: "Inactive", type: "checkbox" }
];

const CancelToken = axios.CancelToken;
let cancelPlayerSearch;

const Player = () => {
  const { token, api } = useContext(SiteContext);

  const navigate = useNavigate();

  const [selectedPlayer, setSelectedPlayer] = useState();
  const [allPlayersSpinner, setAllPlayersSpinner] = useState(false);

  const onSubmit = ({ values }) => {
    api.savePlayer({ values, token, id: selectedPlayer.id }).then(response => {
      if (response?.data?.success) {
        navigate("/admin/player");
      }
    });
  };

  const onCancel = () => setSelectedPlayer(null);

  const doSearch = value => {
    if (value.length > 2) {
      if (cancelPlayerSearch) cancelPlayerSearch();
      api
        .playerSearch(
          {
            text: value
          },
          {
            cancelToken: new CancelToken(function executor(c) {
              cancelPlayerSearch = c;
            })
          }
        )
        .then(d => {
          setResults(d.data);
        })
        .catch(function (thrown) {
          if (axios.isCancel(thrown)) {
            console.log("Request canceled", thrown.message);
          } else {
            alert("error");
          }
        });
    } else setResults(null);
  };

  const loadAllPlayers = value => {
    setAllPlayersSpinner(true);
    api
      .getPlayers()
      .then(d => {
        setResults(d.data);
        setAllPlayersSpinner(false);
      })
      .catch(function (thrown) {
        if (axios.isCancel(thrown)) {
          console.log("Request canceled", thrown.message);
        } else {
          alert("error");
        }
      });
  };

  const deletePlayer = player => {
    if (player) {
      const c = window.confirm(
        `Are you sure you want to delete ${player.fn} ${player.ln}'?`
      );
      if (c)
        api.deletePlayer({ token, id: player.id }).then(response => {
          if (response?.data?.success) {
            setResults([...results.filter(p => p.id !== player.id)]);
          }
        });
    }
  };

  const [search, setSearch] = useState("");
  const [results, setResults] = useState();

  const columns = [
    {
      label: "ID",
      key: row => `${row.id}`,
      el: row => <>{row.id}</>
    },
    {
      label: "Name",
      key: row => `${row.id}name`,
      el: row => (
        <>
          {
            <Button
              variant="link"
              onClick={() => {
                navigate(`/players/${row.id}`);
              }}
            >
              {row.fn} {row.ln}
            </Button>
          }
        </>
      )
    },

    {
      label: "PDGA",
      key: row => `${row.id}pdga`,
      el: row => <>{row.pdga}</>
    },
    {
      label: "Division",
      key: row => `${row.id}division`,
      el: row => <>{row.division}</>
    },
    {
      label: "Balance",
      key: row => `${row.id}balance`,
      el: row => <>{currency.format(row.balance)}</>
    },
    {
      label: "Rounds",
      key: row => `${row.id}roundCountYear`,
      el: row => <>{row.roundCountYear}</>
    },
    {
      label: "",
      key: row => `${row.id}edit`,
      el: row => (
        <>
          {token && (
            <>
              <Button
                variant="link"
                onClick={() => {
                  setSelectedPlayer(row);
                }}
              >
                Edit
              </Button>{" "}
              <Button
                variant="link"
                onClick={() => {
                  deletePlayer(row);
                }}
              >
                Delete
              </Button>
            </>
          )}
        </>
      )
    }
  ];

  return (
    <div className="content">
      <Button onClick={loadAllPlayers}>
        {allPlayersSpinner ? "Loading" : "All Players"}
      </Button>

      <Form noValidate>
        <Form.Group>
          <Form.Label>Search</Form.Label>
          <Form.Control
            type="text"
            placeholder="Search for Player"
            value={search}
            onChange={e => {
              const value = e?.currentTarget?.value;
              setSearch(value);
              doSearch(value);
              // do search
            }}
          />
        </Form.Group>
      </Form>

      {!!results?.length && (
        <Table
          columns={columns}
          data={results}
          rowKey={row => `${row.fn}${row.ln}`}
        />
      )}

      {/* {selectedPlayer && (
        <FormSetup
          onSubmit={onSubmit}
          onCancel={onCancel}
          fields={fieldData.map(f => ({
            ...f,
            default: selectedPlayer[f.name]
          }))}
        />
      )} */}

      {selectedPlayer && (
        <Modal show>
          <Modal.Header>
            <Modal.Title>Edit Player</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <FormSetup
              onSubmit={onSubmit}
              onCancel={onCancel}
              fields={fieldData.map(f => ({
                ...f,
                default: selectedPlayer[f.name]
              }))}
            />
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default Player;
