import React, { useContext, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import FormSetup from "../FormSetup";
import { currency } from "../helpers";
import SiteContext from "../SiteContext";
import Table from "../Table";

import "./Player.scss";

const fieldData = [
  { name: "fn", label: "First", type: "text", required: true },
  { name: "ln", label: "Last", type: "text", required: true },
  { name: "pdga", label: "PDGA", type: "text", required: true },
  { name: "division", label: "Division", type: "text", required: true },
  { name: "inactive", label: "Inactive", type: "checkbox" }
];

const Player = () => {
  const { token, api } = useContext(SiteContext);

  const navigate = useNavigate();

  const [selectedPlayer, setSelectedPlayer] = useState();

  const onSubmit = ({ values }) => {
    api.savePlayer({ values, token, id: selectedPlayer.id }).then(response => {
      if (response?.data?.success) {
        navigate.push("/admin/player");
      }
    });
  };

  const onCancel = () => setSelectedPlayer(null);

  const doSearch = value => {
    if (value.length > 2)
      api.playerSearch({ text: value }).then(d => {
        setResults(d.data);
      });
    else setResults(null);
  };

  const [search, setSearch] = useState("");
  const [results, setResults] = useState();

  const columns = [
    {
      label: "Name",
      key: row => `${row.id}name`,
      el: row => (
        <Button
          variant="link"
          onClick={() => {
            setSelectedPlayer(row);
          }}
        >
          {row.fn} {row.ln}
        </Button>
      )
    },
    {
      label: "PDGA",
      key: row => `${row.id}pdga`,
      el: row => <>{row.pdga}</>
    },
    {
      label: "Division",
      key: row => `${row.id}division`,
      el: row => <>{row.division}</>
    },
    {
      label: "Balance",
      key: row => `${row.id}balance`,
      el: row => <>{currency.format(row.balance)}</>
    }
  ];

  return (
    <div className="content">
      {!selectedPlayer && (
        <Form noValidate>
          <Form.Group>
            <Form.Label>Search</Form.Label>
            <Form.Control
              type="text"
              placeholder="Search for Player"
              value={search}
              onChange={e => {
                const value = e?.currentTarget?.value;
                setSearch(value);
                doSearch(value);
                // do search
              }}
            />
          </Form.Group>
        </Form>
      )}

      {!selectedPlayer && !!results?.length && (
        <Table
          columns={columns}
          data={results}
          rowKey={row => `${row.fn}${row.ln}`}
        />
      )}

      {selectedPlayer && (
        <FormSetup
          onSubmit={onSubmit}
          onCancel={onCancel}
          fields={fieldData.map(f => ({
            ...f,
            default: selectedPlayer[f.name]
          }))}
        />
      )}
    </div>
  );
};

export default Player;
