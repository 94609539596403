import { useState, useMemo, useCallback } from "react";
import axios from "axios";

// global storage
// https://github.com/embbnux/use-global-storage
// https://github.com/embbnux/use-global-storage-demo
/*
const oqStore = useGlobalStorage({
  storageOptions: { name: "UFG_STORE" }
});
*/

// const useLocalStorageState = (key, defaultValue) => {
//   const [value, setValue] = useState(() => {
//     const stickyValue = window.localStorage.getItem(key);
//     return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
//   });
//   useEffect(() => {
//     window.localStorage.setItem(key, JSON.stringify(value));
//   }, [key, value]);
//   return [value, setValue];
// };

export default function useSite() {
  const getToken = () => {
    return localStorage.getItem("token");
  };

  const [token, setToken] = useState(getToken());

  const saveToken = useCallback(userToken => {
    localStorage.setItem("token", userToken);
    setToken(userToken);
  }, []);

  const clearToken = useCallback(userToken => {
    localStorage.removeItem("token");
    setToken(null);
  }, []);

  const apiFactory = useCallback(() => {
    const baseURL =
      process.env.REACT_APP_ENV === "dev"
        ? `http://${window.location.hostname}:3031/`
        : `${process.env.REACT_APP_API}/`;
    const config = { baseURL };

    if (false) {
      config.headers = {
        Pragma: "no-cache",
        "Cache-Control": "no-cache, no-store",
        cache: "no-cache"
      };
    }

    const service = axios.create(config);

    service.interceptors.request.use(config => {
      if (config.delay) {
        return new Promise(resolve =>
          setTimeout(() => resolve(config), config.delay)
        );
      }
      return config;
    });

    service.interceptors.response.use(
      function (response) {
        if (response.data.error === "Not an admin") {
          clearToken();
        }
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
      }
    );

    return service;
  }, [clearToken]);

  const svc = apiFactory();

  return useMemo(
    () => ({
      saveToken,
      token,
      clearToken,
      apiFactory,
      api: {
        login: credentials => {
          return svc.post(`/api/login`, credentials);
        },
        getRound: ({ roundId }) => {
          return svc.get(`/api/rounds/round/${roundId}`);
        },
        getRounds: ({ range }) => {
          return svc.get(`/api/rounds/${range}`);
        },
        getPlayers: () => {
          return svc.get(`/api/players`);
        },
        getPlayer: ({ playerId }) => {
          return svc.get(`/api/players/player/${playerId}`);
        },
        getPlayerSimpleList: () => {
          return svc.get(`/api/players/simpleList`);
        },
        playerSearch: ({ text }, options = {}) => {
          return svc.get(`/api/players/search/${text}`, options);
        },
        getRoundsByPlayer: ({ playerId }) => {
          return svc.get(`/api/rounds/roundsByPlayer/${playerId}`);
        },
        getTransfers: () => {
          return svc.get(`/api/transfers`);
        },
        saveTransfer: payload => {
          return svc.post(`/api/transfers/add`, payload);
        },
        deleteTransfer: payload => {
          return svc.post(`/api/transfers/delete`, payload);
        },
        getTransfersByPlayer: ({ playerId }) => {
          return svc.get(`/api/transfers/transfersByPlayer/${playerId}`);
        },
        getPlayersInRound: ({ roundId }) => {
          return svc.get(`/api/players/round/${roundId}`);
        },
        saveRound: payload => {
          return svc.post(`/api/admin/round/`, payload);
        },
        savePlayer: payload => {
          return svc.post(`/api/admin/player/`, payload);
        },
        deletePlayer: payload => {
          return svc.post(`/api/admin/player/delete`, payload);
        },
        deleteRound: payload => {
          return svc.post(`/api/admin/round/delete`, payload);
        },
        addUser: payload => {
          return svc.post(`/api/admin/user/add`, payload);
        },
        getAcePot: () => {
          return svc.get(`/api/ace`);
        },
        getAces: () => {
          return svc.get(`/api/ace/list`);
        },
        saveAce: payload => {
          return svc.post(`/api/ace/add`, payload);
        },
        deleteAce: payload => {
          return svc.post(`/api/ace/delete`, payload);
        },
        addEntry: payload => {
          return svc.post(`/api/admin/entry/add`, payload);
        },
        updateEntry: payload => {
          return svc.post(`/api/admin/entry/update`, payload);
        },
        bulkEntryUpdate: payload => {
          return svc.post(`/api/admin/entry/bulk`, payload);
        },
        updatePayouts: payload => {
          return svc.post(`/api/admin/entry/payouts`, payload);
        },
        getEntries: ({ roundId }) => {
          return svc.get(`/api/entries/${roundId}`);
        },
        getEntriesMulti: ({ roundIds }) => {
          return svc.get(`/api/entries/multi/${roundIds}`);
        },
        deleteEntry: payload => {
          return svc.post(`/api/admin/entry/delete`, payload);
        }
      }
    }),
    [saveToken, token, clearToken, apiFactory, svc]
  );
}
