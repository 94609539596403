import { format } from "date-fns";

export const dt = (d, fm) => {
  // Create date from Unix timestamp (seconds)
  const date = new Date(d * 1000);
  // Get local midnight by setting hours to 0
  date.setHours(0, 0, 0, 0);

  const q = {
    long: "MMMM d, y",
    stacked: "MM/d"
  };

  const f = fm ? q[fm] : "MM/dd/yyyy";
  if (fm === "stacked")
    return (
      <div>
        {format(date, "MM/dd")}
        <div>{format(date, "yyyy")}</div>
      </div>
    );
  return format(date, f);
};

const isNumberString = n => /^[0-9,.]*$/.test(n);

const cleanSortItem = (a, property) => {
  // if it's not a number, make sure it's lowercase
  // try to Number-ize the comparing value... if it's NaN, then use the string value
  return !!a
    ? isNaN(a[property]) // it's a string
      ? isNumberString(a[property]) // is it a string number?
        ? parseFloat(a[property].split(",").join("")) // it's a string number, remove commas if they exist
        : a[property]?.toLowerCase() // not a number
      : parseFloat(a[property]) // it's a number
    : "";
};

/**
 * properties = [{"field":"name","ascending":true},
 * {"field":"coverageCategoryDisplayName","ascending":false}]
 */
export const sortByProperties = propArr => {
  // usage: arrayOfObjects.sort(sortByProperties([{field:"name",asc:true},
  //                                            {field:"coverageType",asc:false}]))
  return function (a, b) {
    let result = 0;
    for (let i = 0; i < propArr.length; i++) {
      const A = cleanSortItem(a, propArr[i].field);
      const B = cleanSortItem(b, propArr[i].field);

      // if the property isn't available, just return the data
      if (!A || !B) return 0;

      // sort it..the property that comes fast has more weight in sort order.. i.e name higher than coverageType.
      if (!!!propArr[i]?.asc)
        result += (propArr.length - i) * (A > B ? -1 : A < B ? 1 : 0);
      else result += (propArr.length - i) * (A < B ? -1 : A > B ? 1 : 0);
    }
    return result;
  };
};

// usage: arrayOfObjects.sort(sortByProperty("propertyName"))
export const sortByProperty = (property, asc = true) => {
  return sortByProperties([{ field: property, asc }]);
};

// Create our number formatter.
export const currency = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 2 // (causes 2500.99 to be printed as $2,501)
});

export const divisions =
  "MPO,MP40,MP50,MPM,MPG,FPO,MA1,MA2,MA3,MA4,MA40,MA50,FA1,FA2,FA3,FA40,FA50,MJ18,MJ15,MJ12,MJ10,MJ08,MJ06".split(
    ","
  );

export const arrayToOptions = a => a.map(v => ({ label: v, value: v }));
