import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useApi } from "../../services/useApi";
//import * as api from "../../services/api";
import SiteContext from "../SiteContext";
import FormSetup from "../FormSetup";
import { Button } from "react-bootstrap";

import "./Rounds.scss";

const fieldData = [
  { name: "d", label: "Date", type: "date", required: true },
  { name: "n", label: "Name", type: "text", required: true },
  { name: "loc", label: "Location", type: "text", required: true },
  {
    name: "fee_pdga",
    label: "PDGA Fee",
    type: "text",
    default: ".5",
    required: true
  },
  {
    name: "fee_course",
    label: "Course Fund",
    type: "text",
    default: "1",
    required: true
  },
  {
    name: "fee_other",
    label: "Other Fee",
    type: "text",
    default: "0",
    required: true
  },
  {
    name: "ace",
    label: "Ace Cost",
    type: "text",
    default: "1",
    required: true
  },
  {
    name: "payout",
    label: "Payout Amount",
    type: "text",
    default: "4.5",
    required: true
  },
  {
    name: "pdga_url",
    label: "PDGA Url",
    type: "text",
    default: "",
    required: false
  }
];

const Round = () => {
  const { token, api } = useContext(SiteContext);

  const { roundId } = useParams();

  const navigate = useNavigate();

  const [fields, setFields] = useState();

  const { loading: roundLoading, runApi: roundApi } = useApi({
    api: api.getRound
  });

  useEffect(() => {
    if (!fields) {
      if (roundId && !roundLoading)
        roundApi({ roundId }).then(data => {
          const newData = { ...data };
          const newFields = fieldData.map(f => ({
            ...f,
            default: newData[f.name]
          }));
          setFields(newFields);
        });
      else setFields(fieldData);
    }
  }, [fields, roundApi, roundId, roundLoading]);

  const onSubmit = ({ values }) => {
    api.saveRound({ values, token, id: roundId }).then(response => {
      if (response?.data?.success) {
        navigate("/admin/rounds");
      }
    });
  };

  const onDelete = () => {
    if (roundId) {
      const c = window.confirm("Are you sure you want to delete this round?");
      if (c)
        api.deleteRound({ token, id: roundId }).then(response => {
          if (response?.data?.success) {
            navigate("/admin/rounds");
          }
        });
    }
  };

  const onCancel = () => navigate("/admin/rounds");

  return (
    <div className="content">
      {roundLoading && <div>LOADING...</div>}
      {fields && (
        <FormSetup onSubmit={onSubmit} onCancel={onCancel} fields={fields} />
      )}
      <div className="align-items-right">
        <Button variant="danger" type="button" onClick={onDelete}>
          Delete
        </Button>
      </div>
    </div>
  );
};

export default Round;
