import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { sortByProperties, dt } from "../helpers";
import { useApi } from "../../services/useApi";
import SiteContext from "../SiteContext";
import { Container, Row, Col } from "react-bootstrap";

// import cn from "classnames";
// import * as api from "../../services/api";

import { Button } from "react-bootstrap";

import Table from "../Table";

// function nextDate(dayIndex) {
//   var today = new Date();
//   today.setDate(
//     today.getDate() + ((dayIndex - 1 - today.getDay() + 7) % 7) + 1
//   );
//   return today;
// }

//const now = new Date();
//const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
// const weekStart =
//   new Date(today.setDate(today.getDate() - today.getDay())).getTime() / 1000;
// const weekEnd = nextDate(6).getTime() / 1000;

const Rounds = ({ range = "all" }) => {
  const { api } = useContext(SiteContext);

  const [showCheckboxes, setShowCheckboxes] = useState(false);
  const [showPastRounds, setShowPastRounds] = useState(true);

  const [data, setData] = useState();
  // const [which, setWhich] = useState("past");
  const navigate = useNavigate();
  const [selectedRounds, setSelectedRounds] = useState({});

  const { loading: roundsLoading, runApi: roundsApi } = useApi({
    api: api.getRounds
  });

  useEffect(() => {
    if (!data && !roundsLoading)
      roundsApi({ range }).then(d => {
        setData(d.sort(sortByProperties([{ field: "d", asc: false }])));
      });
  }, [data, range, roundsApi, roundsLoading]);

  const toggleRoundSelection = roundId => {
    setSelectedRounds(prev => {
      const newSelected = { ...prev };
      if (newSelected[roundId]) {
        delete newSelected[roundId];
      } else {
        newSelected[roundId] = true;
      }
      return newSelected;
    });
  };

  const clearSelection = () => {
    setSelectedRounds({});
  };

  const columns = [
    {
      label: "Date",
      key: row => `${row.id}date`,
      el: row => <Link to={`/admin/round/play/${row.id}`}>{dt(row.d)}</Link>
    },
    {
      label: "Name",
      key: row => `${row.id}name`,
      el: row => <Link to={`/admin/round/play/${row.id}`}>{row.n}</Link>
    },
    {
      label: "Location",
      key: row => `${row.id}loc`,
      el: row => <>{row.loc}</>
    },
    // {
    //   label: "",
    //   key: row => `${row.id}proc`,
    //   el: row => <>{!!row.processed && "✓"}</>,
    //   align: "center"
    // },
    {
      label: "Players",
      key: row => `${row.id}`,
      el: row => <>{row.entry_count}</>
    },
    {
      label: "",
      key: row => `${row.id}go`,
      el: row => (
        <>
          {
            //row.d > weekStart && row.d < weekEnd &&
            <Button
              size="sm"
              variant="link"
              onClick={() => navigate(`/admin/rounds/${row.id}`)}
            >
              Edit
            </Button>
          }
        </>
      )
    }
  ];

  if (showCheckboxes) {
    columns.unshift({
      label: "",
      className: "checkbox-column",
      key: row => `${row.id}select`,
      el: row => (
        <input
          type="checkbox"
          checked={!!selectedRounds[row.id]}
          onChange={() => toggleRoundSelection(row.id)}
        />
      ),
      align: "center"
    });
  }

  const today = new Date();
  const now = today.getTime() / 1000;

  const selectedRoundIds = Object.keys(selectedRounds);
  const hasSelectedRounds = selectedRoundIds.length > 0;

  const handleEditSelected = () => {
    navigate("/admin/rounds/multi-edit", {
      state: { selectedRoundIds: selectedRoundIds.map(id => parseInt(id)) }
    });
  };

  // Filter data for past and future rounds
  const pastRounds = data ? data.filter(r => r.d < now) : [];
  const futureRounds = data
    ? data
        .filter(r => r.d > now)
        .sort(sortByProperties([{ field: "d", asc: true }]))
    : [];

  return (
    <div className="content">
      <div className="d-flex gap-2 align-items-center mb-3">
        <Button
          variant="outline-primary"
          onClick={() => navigate("/admin/rounds/new")}
          size="sm"
        >
          Add New Round
        </Button>
        {hasSelectedRounds && (
          <>
            <Button
              variant="outline-success"
              className="me-2"
              onClick={handleEditSelected}
              size="sm"
            >
              Edit Selected ({selectedRoundIds.length})
            </Button>
            <Button
              variant="outline-secondary"
              className="me-2"
              onClick={clearSelection}
              size="sm"
            >
              Clear Selection
            </Button>
          </>
        )}
        <Button
          variant="outline-primary"
          onClick={() => navigate("/admin/rounds/multi-edit")}
          size="sm"
        >
          Multi-Round Editor
        </Button>
      </div>

      {roundsLoading && "Loading Data..."}

      {!!data?.length && (
        <Container fluid>
          {/**
           * if window is small, show links to #past-rounds and #future-rounds
           */}
          <div className="d-flex gap-2 mb-3">
            <Button
              variant="link"
              onClick={() => setShowCheckboxes(!showCheckboxes)}
            >
              {showCheckboxes ? "Hide checkboxes" : "Show checkboxes"}
            </Button>
            {window.innerWidth < 768 && (
              <Button
                variant="link"
                onClick={() => setShowPastRounds(!showPastRounds)}
              >
                Show {showPastRounds ? "Future" : "Past"} Rounds
              </Button>
            )}
          </div>
          <Row>
            {(window.innerWidth >= 768 || showPastRounds) && (
              <Col>
                <h5 id="past-rounds">Past Rounds</h5>
                <Table
                  columns={columns}
                  data={pastRounds}
                  rowKey={row => `row${row.id}`}
                />
              </Col>
            )}
            {(window.innerWidth >= 768 || !showPastRounds) && (
              <Col>
                <h5 id="future-rounds">Future Rounds</h5>
                <Table
                  columns={columns}
                  data={futureRounds}
                  rowKey={row => `row${row.id}`}
                />
              </Col>
            )}
          </Row>
        </Container>
      )}
    </div>
  );
};

export default Rounds;
