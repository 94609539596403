import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import SiteContext from "./SiteContext";

const ProtectedRoute = ({ children }) => {
  const { token } = useContext(SiteContext);
  const location = useLocation();

  if (!token) {
    // Redirect to login while saving the attempted URL
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRoute;
