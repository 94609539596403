import React, { useContext, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
// import Entries from "./Entries";
// import EntriesScores from "./EntriesScores";
// import Player from "./Player";
// import User from "./User";
// import Round from "./Round";
// import RoundMultiEdit from "./RoundMultiEdit";
// import Rounds from "./Rounds";
import SiteContext from "../SiteContext";

const CryptoJS = require("crypto-js");

const Admin = () => {
  const navigate = useNavigate();

  const { token, clearToken } = useContext(SiteContext);
  const cryptKey = process.env.REACT_APP_CRYPTO;

  useEffect(() => {
    if (!token) {
      navigate("/login");
    } else {
      const timestamp = CryptoJS.AES.decrypt(token, cryptKey).toString(
        CryptoJS.enc.Utf8
      );

      const t = parseInt(timestamp);
      const d = new Date(t);
      const today = new Date();

      const diffTime = Math.abs(today - d);
      //const diffMinutes = Math.ceil(diffTime / (1000 * 60));
      const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
      //const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffHours > 72) {
        clearToken();
        navigate("/login");
      }
    }
  }, [clearToken, cryptKey, navigate, token]);

  // In React Router v7, the routes are defined isn App.js
  // This component just renders the Outlet for nested routes
  return (
    <div id="admin">
      <Outlet />
    </div>
  );
};

export default Admin;
