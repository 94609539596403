import { divisions, sortByProperty } from "../helpers";

export const payouts40 = [
  [
    1, 0.625, 0.475, 0.41, 0.36, 0.33, 0.3, 0.28, 0.268, 0.26, 0.25, 0.238,
    0.232, 0.225, 0.22, 0.21, 0.2, 0.19, 0.18, 0.165, 0.152, 0.145, 0.142, 0.14,
    0.137, 0.132, 0.13, 0.127, 0.125, 0.122, 0.118, 0.115, 0.112
  ],

  [
    "",
    0.375,
    0.3,
    0.26,
    0.245,
    0.23,
    0.21,
    0.19,
    0.185,
    0.18,
    0.165,
    0.16,
    0.155,
    0.15,
    0.145,
    0.14,
    0.135,
    0.13,
    0.125,
    0.118,
    0.115,
    0.112,
    0.11,
    0.107,
    0.105,
    0.1,
    0.098,
    0.095,
    0.093,
    0.09,
    0.087,
    0.085,
    0.082
  ],

  [
    "",
    "",
    0.225,
    0.19,
    0.17,
    0.155,
    0.15,
    0.145,
    0.14,
    0.13,
    0.127,
    0.12,
    0.115,
    0.11,
    0.105,
    0.1,
    0.097,
    0.095,
    0.092,
    0.089,
    0.087,
    0.085,
    0.084,
    0.082,
    0.078,
    0.076,
    0.075,
    0.073,
    0.071,
    0.068,
    0.065,
    0.063,
    0.058
  ],

  [
    "",
    "",
    "",
    0.14,
    0.125,
    0.12,
    0.11,
    0.115,
    0.11,
    0.1,
    0.098,
    0.095,
    0.09,
    0.085,
    0.084,
    0.083,
    0.081,
    0.08,
    0.078,
    0.076,
    0.075,
    0.072,
    0.071,
    0.066,
    0.065,
    0.064,
    0.063,
    0.061,
    0.059,
    0.056,
    0.055,
    0.053,
    0.052
  ],

  [
    "",
    "",
    "",
    "",
    0.1,
    0.095,
    0.095,
    0.09,
    0.085,
    0.081,
    0.08,
    0.077,
    0.075,
    0.071,
    0.069,
    0.068,
    0.067,
    0.065,
    0.065,
    0.064,
    0.063,
    0.061,
    0.06,
    0.057,
    0.056,
    0.055,
    0.054,
    0.052,
    0.05,
    0.049,
    0.048,
    0.048,
    0.048
  ],

  [
    "",
    "",
    "",
    "",
    "",
    0.07,
    0.075,
    0.07,
    0.067,
    0.067,
    0.065,
    0.064,
    0.061,
    0.06,
    0.058,
    0.057,
    0.056,
    0.055,
    0.055,
    0.054,
    0.054,
    0.053,
    0.052,
    0.051,
    0.049,
    0.048,
    0.047,
    0.046,
    0.045,
    0.045,
    0.045,
    0.045,
    0.045
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    0.06,
    0.06,
    0.055,
    0.055,
    0.054,
    0.054,
    0.052,
    0.052,
    0.05,
    0.05,
    0.05,
    0.05,
    0.05,
    0.049,
    0.049,
    0.048,
    0.046,
    0.045,
    0.045,
    0.044,
    0.044,
    0.043,
    0.042,
    0.042,
    0.042,
    0.042,
    0.042
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.05,
    0.047,
    0.047,
    0.047,
    0.047,
    0.046,
    0.046,
    0.045,
    0.045,
    0.045,
    0.045,
    0.045,
    0.045,
    0.045,
    0.045,
    0.042,
    0.042,
    0.041,
    0.041,
    0.04,
    0.04,
    0.04,
    0.04,
    0.04,
    0.039,
    0.039
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.043,
    0.042,
    0.042,
    0.042,
    0.042,
    0.042,
    0.041,
    0.041,
    0.041,
    0.041,
    0.041,
    0.041,
    0.041,
    0.041,
    0.039,
    0.039,
    0.038,
    0.038,
    0.037,
    0.037,
    0.037,
    0.037,
    0.037,
    0.036,
    0.036
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.038,
    0.038,
    0.038,
    0.038,
    0.038,
    0.037,
    0.037,
    0.037,
    0.037,
    0.037,
    0.037,
    0.037,
    0.037,
    0.036,
    0.036,
    0.035,
    0.035,
    0.034,
    0.034,
    0.034,
    0.034,
    0.034,
    0.034,
    0.034
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.034,
    0.034,
    0.034,
    0.034,
    0.034,
    0.034,
    0.034,
    0.034,
    0.034,
    0.034,
    0.034,
    0.034,
    0.033,
    0.033,
    0.033,
    0.033,
    0.032,
    0.032,
    0.032,
    0.032,
    0.032,
    0.032,
    0.032
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.031,
    0.031,
    0.031,
    0.031,
    0.031,
    0.031,
    0.031,
    0.031,
    0.031,
    0.031,
    0.031,
    0.031,
    0.031,
    0.031,
    0.031,
    0.03,
    0.03,
    0.03,
    0.03,
    0.03,
    0.03,
    0.03
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.029,
    0.029,
    0.029,
    0.029,
    0.029,
    0.029,
    0.029,
    0.029,
    0.029,
    0.029,
    0.029,
    0.029,
    0.029,
    0.029,
    0.028,
    0.028,
    0.028,
    0.028,
    0.028,
    0.028,
    0.028
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.027,
    0.027,
    0.027,
    0.027,
    0.027,
    0.027,
    0.027,
    0.027,
    0.027,
    0.027,
    0.027,
    0.027,
    0.027,
    0.027,
    0.027,
    0.027,
    0.027,
    0.027,
    0.027,
    0.027
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.025,
    0.025,
    0.025,
    0.025,
    0.025,
    0.026,
    0.026,
    0.026,
    0.026,
    0.026,
    0.026,
    0.026,
    0.026,
    0.026,
    0.026,
    0.026,
    0.026,
    0.026,
    0.026
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.023,
    0.023,
    0.023,
    0.023,
    0.025,
    0.025,
    0.025,
    0.025,
    0.025,
    0.025,
    0.025,
    0.025,
    0.025,
    0.025,
    0.025,
    0.025,
    0.025,
    0.025
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.022,
    0.022,
    0.022,
    0.024,
    0.024,
    0.024,
    0.024,
    0.024,
    0.024,
    0.024,
    0.024,
    0.024,
    0.024,
    0.024,
    0.024,
    0.024,
    0.024
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.021,
    0.021,
    0.023,
    0.023,
    0.023,
    0.023,
    0.023,
    0.023,
    0.023,
    0.023,
    0.023,
    0.023,
    0.023,
    0.023,
    0.023,
    0.023
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.02,
    0.022,
    0.022,
    0.022,
    0.022,
    0.022,
    0.022,
    0.022,
    0.022,
    0.022,
    0.022,
    0.022,
    0.022,
    0.022,
    0.022
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.021,
    0.021,
    0.021,
    0.021,
    0.021,
    0.021,
    0.021,
    0.021,
    0.021,
    0.021,
    0.021,
    0.021,
    0.021,
    0.021
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.02,
    0.02,
    0.02,
    0.02,
    0.02,
    0.02,
    0.02,
    0.02,
    0.02,
    0.02,
    0.02,
    0.02,
    0.02
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.019,
    0.019,
    0.019,
    0.019,
    0.019,
    0.019,
    0.019,
    0.019,
    0.019,
    0.019,
    0.019,
    0.019
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.018,
    0.018,
    0.018,
    0.018,
    0.018,
    0.018,
    0.018,
    0.018,
    0.018,
    0.017,
    0.017
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.017,
    0.017,
    0.017,
    0.017,
    0.017,
    0.017,
    0.017,
    0.017,
    0.017,
    0.017
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.016,
    0.016,
    0.016,
    0.016,
    0.016,
    0.016,
    0.016,
    0.016,
    0.016
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.016,
    0.015,
    0.015,
    0.015,
    0.015,
    0.015,
    0.015,
    0.015
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.015,
    0.015,
    0.014,
    0.014,
    0.014,
    0.014,
    0.014
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.014,
    0.014,
    0.014,
    0.013,
    0.013,
    0.013
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.013,
    0.013,
    0.013,
    0.013,
    0.013
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.013,
    0.013,
    0.013,
    0.013
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.013,
    0.013,
    0.013
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.012,
    0.012
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.012
  ]
];

export const payouts45 = [
  [
    1, 0.6, 0.45, 0.39, 0.3, 0.25, 0.21, 0.19, 0.17, 0.15, 0.14, 0.13, 0.115,
    0.11, 0.1, 0.095, 0.09, 0.085, 0.08, 0.075, 0.072, 0.07, 0.07, 0.065, 0.062,
    0.06, 0.057, 0.055, 0.055, 0.052, 0.05, 0.048, 0.046
  ],

  [
    "",
    0.4,
    0.325,
    0.29,
    0.25,
    0.22,
    0.19,
    0.17,
    0.15,
    0.14,
    0.13,
    0.12,
    0.11,
    0.1,
    0.095,
    0.09,
    0.085,
    0.08,
    0.075,
    0.07,
    0.068,
    0.065,
    0.065,
    0.06,
    0.058,
    0.055,
    0.055,
    0.05,
    0.05,
    0.05,
    0.048,
    0.046,
    0.044
  ],

  [
    "",
    "",
    0.225,
    0.2,
    0.2,
    0.18,
    0.17,
    0.15,
    0.14,
    0.13,
    0.12,
    0.11,
    0.105,
    0.095,
    0.09,
    0.085,
    0.08,
    0.075,
    0.075,
    0.07,
    0.065,
    0.065,
    0.06,
    0.06,
    0.055,
    0.055,
    0.053,
    0.05,
    0.05,
    0.048,
    0.046,
    0.045,
    0.044
  ],

  [
    "",
    "",
    "",
    0.12,
    0.15,
    0.15,
    0.14,
    0.13,
    0.12,
    0.12,
    0.11,
    0.1,
    0.095,
    0.09,
    0.085,
    0.08,
    0.075,
    0.075,
    0.07,
    0.065,
    0.065,
    0.06,
    0.06,
    0.055,
    0.055,
    0.055,
    0.05,
    0.05,
    0.05,
    0.045,
    0.045,
    0.044,
    0.042
  ],

  [
    "",
    "",
    "",
    "",
    0.1,
    0.12,
    0.12,
    0.12,
    0.11,
    0.11,
    0.1,
    0.09,
    0.09,
    0.085,
    0.08,
    0.075,
    0.075,
    0.07,
    0.065,
    0.065,
    0.06,
    0.06,
    0.055,
    0.055,
    0.055,
    0.05,
    0.05,
    0.05,
    0.045,
    0.045,
    0.044,
    0.042,
    0.042
  ],

  [
    "",
    "",
    "",
    "",
    "",
    0.08,
    0.1,
    0.1,
    0.1,
    0.09,
    0.09,
    0.09,
    0.08,
    0.08,
    0.075,
    0.075,
    0.07,
    0.065,
    0.065,
    0.06,
    0.06,
    0.055,
    0.055,
    0.055,
    0.05,
    0.05,
    0.05,
    0.045,
    0.045,
    0.045,
    0.042,
    0.04,
    0.04
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    0.07,
    0.08,
    0.08,
    0.08,
    0.08,
    0.08,
    0.075,
    0.075,
    0.07,
    0.07,
    0.065,
    0.065,
    0.06,
    0.06,
    0.055,
    0.055,
    0.05,
    0.05,
    0.05,
    0.05,
    0.05,
    0.045,
    0.045,
    0.045,
    0.04,
    0.04,
    0.04
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.06,
    0.07,
    0.07,
    0.07,
    0.07,
    0.07,
    0.07,
    0.065,
    0.065,
    0.065,
    0.06,
    0.06,
    0.055,
    0.055,
    0.055,
    0.05,
    0.05,
    0.05,
    0.045,
    0.045,
    0.045,
    0.045,
    0.04,
    0.04,
    0.04,
    0.038
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.06,
    0.06,
    0.06,
    0.06,
    0.065,
    0.065,
    0.06,
    0.06,
    0.06,
    0.055,
    0.055,
    0.055,
    0.05,
    0.05,
    0.05,
    0.05,
    0.045,
    0.045,
    0.045,
    0.045,
    0.04,
    0.04,
    0.04,
    0.04,
    0.038
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.05,
    0.05,
    0.06,
    0.06,
    0.06,
    0.06,
    0.055,
    0.055,
    0.055,
    0.055,
    0.05,
    0.05,
    0.05,
    0.045,
    0.045,
    0.045,
    0.045,
    0.045,
    0.04,
    0.04,
    0.04,
    0.04,
    0.04,
    0.036
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.05,
    0.05,
    0.05,
    0.05,
    0.055,
    0.05,
    0.05,
    0.05,
    0.05,
    0.05,
    0.05,
    0.045,
    0.045,
    0.045,
    0.045,
    0.04,
    0.04,
    0.04,
    0.04,
    0.04,
    0.04,
    0.035,
    0.036
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.04,
    0.045,
    0.045,
    0.05,
    0.05,
    0.05,
    0.045,
    0.05,
    0.045,
    0.045,
    0.045,
    0.045,
    0.04,
    0.04,
    0.04,
    0.04,
    0.04,
    0.04,
    0.035,
    0.035,
    0.035,
    0.036
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.04,
    0.04,
    0.045,
    0.045,
    0.045,
    0.045,
    0.045,
    0.045,
    0.045,
    0.04,
    0.04,
    0.04,
    0.04,
    0.04,
    0.04,
    0.04,
    0.035,
    0.035,
    0.035,
    0.035,
    0.034
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.03,
    0.04,
    0.04,
    0.04,
    0.04,
    0.04,
    0.04,
    0.04,
    0.04,
    0.04,
    0.04,
    0.04,
    0.04,
    0.035,
    0.035,
    0.035,
    0.035,
    0.035,
    0.035,
    0.034
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.03,
    0.035,
    0.035,
    0.04,
    0.04,
    0.04,
    0.04,
    0.04,
    0.04,
    0.035,
    0.035,
    0.035,
    0.035,
    0.035,
    0.035,
    0.035,
    0.035,
    0.035,
    0.032
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.03,
    0.03,
    0.035,
    0.035,
    0.035,
    0.035,
    0.035,
    0.035,
    0.035,
    0.035,
    0.035,
    0.035,
    0.035,
    0.035,
    0.035,
    0.03,
    0.03,
    0.032
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.03,
    0.03,
    0.03,
    0.035,
    0.035,
    0.035,
    0.035,
    0.035,
    0.035,
    0.035,
    0.03,
    0.03,
    0.03,
    0.03,
    0.03,
    0.03,
    0.03
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.03,
    0.025,
    0.03,
    0.03,
    0.03,
    0.03,
    0.03,
    0.03,
    0.03,
    0.03,
    0.03,
    0.03,
    0.03,
    0.03,
    0.03,
    0.03
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.025,
    0.03,
    0.03,
    0.03,
    0.03,
    0.03,
    0.03,
    0.03,
    0.03,
    0.03,
    0.03,
    0.03,
    0.03,
    0.03,
    0.028
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.025,
    0.025,
    0.025,
    0.03,
    0.03,
    0.03,
    0.03,
    0.03,
    0.03,
    0.03,
    0.03,
    0.03,
    0.03,
    0.028
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.025,
    0.025,
    0.025,
    0.025,
    0.025,
    0.025,
    0.025,
    0.025,
    0.025,
    0.025,
    0.025,
    0.025,
    0.026
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.025,
    0.025,
    0.025,
    0.025,
    0.025,
    0.025,
    0.025,
    0.025,
    0.025,
    0.025,
    0.025,
    0.026
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.02,
    0.025,
    0.025,
    0.025,
    0.025,
    0.025,
    0.025,
    0.025,
    0.025,
    0.025,
    0.024
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.02,
    0.02,
    0.02,
    0.02,
    0.025,
    0.025,
    0.025,
    0.025,
    0.025,
    0.024
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.02,
    0.02,
    0.02,
    0.02,
    0.02,
    0.02,
    0.025,
    0.02,
    0.022
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.02,
    0.02,
    0.02,
    0.02,
    0.02,
    0.02,
    0.02,
    0.022
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.02,
    0.02,
    0.02,
    0.02,
    0.02,
    0.02,
    0.02
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.02,
    0.02,
    0.02,
    0.02,
    0.02,
    0.02
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.015,
    0.02,
    0.02,
    0.02,
    0.018
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.015,
    0.015,
    0.02,
    0.018
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.015,
    0.015,
    0.018
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.015,
    0.016
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.016
  ]
];

export const payouts50 = [
  [
    1, 0.625, 0.475, 0.41, 0.345, 0.306, 0.274, 0.249, 0.228, 0.211, 0.195,
    0.181, 0.17, 0.159, 0.15, 0.143, 0.135, 0.129, 0.125, 0.118, 0.113, 0.11,
    0.107, 0.102, 0.1, 0.095, 0.093, 0.09, 0.088, 0.085, 0.083, 0.08, 0.078
  ],

  [
    "",
    0.375,
    0.3,
    0.26,
    0.24,
    0.218,
    0.2,
    0.185,
    0.173,
    0.162,
    0.151,
    0.142,
    0.134,
    0.127,
    0.121,
    0.115,
    0.111,
    0.105,
    0.101,
    0.097,
    0.094,
    0.091,
    0.088,
    0.085,
    0.083,
    0.08,
    0.078,
    0.077,
    0.074,
    0.071,
    0.071,
    0.069,
    0.067
  ],

  [
    "",
    "",
    0.225,
    0.19,
    0.18,
    0.168,
    0.158,
    0.149,
    0.141,
    0.134,
    0.127,
    0.12,
    0.114,
    0.109,
    0.104,
    0.1,
    0.096,
    0.092,
    0.089,
    0.086,
    0.083,
    0.081,
    0.078,
    0.076,
    0.074,
    0.072,
    0.071,
    0.069,
    0.067,
    0.066,
    0.065,
    0.063,
    0.061
  ],

  [
    "",
    "",
    "",
    0.14,
    0.135,
    0.13,
    0.126,
    0.121,
    0.117,
    0.112,
    0.107,
    0.103,
    0.098,
    0.095,
    0.091,
    0.088,
    0.085,
    0.082,
    0.08,
    0.077,
    0.075,
    0.073,
    0.071,
    0.069,
    0.068,
    0.066,
    0.065,
    0.063,
    0.062,
    0.061,
    0.059,
    0.058,
    0.057
  ],

  [
    "",
    "",
    "",
    "",
    0.1,
    0.101,
    0.1,
    0.099,
    0.096,
    0.094,
    0.091,
    0.088,
    0.085,
    0.082,
    0.079,
    0.077,
    0.075,
    0.073,
    0.071,
    0.069,
    0.067,
    0.066,
    0.064,
    0.063,
    0.061,
    0.06,
    0.059,
    0.058,
    0.057,
    0.055,
    0.055,
    0.054,
    0.053
  ],

  [
    "",
    "",
    "",
    "",
    "",
    0.077,
    0.079,
    0.079,
    0.079,
    0.078,
    0.076,
    0.074,
    0.072,
    0.07,
    0.069,
    0.067,
    0.065,
    0.064,
    0.062,
    0.061,
    0.06,
    0.058,
    0.057,
    0.056,
    0.055,
    0.054,
    0.053,
    0.052,
    0.052,
    0.051,
    0.05,
    0.049,
    0.048
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    0.063,
    0.065,
    0.065,
    0.065,
    0.065,
    0.064,
    0.063,
    0.061,
    0.06,
    0.059,
    0.058,
    0.057,
    0.056,
    0.055,
    0.054,
    0.053,
    0.052,
    0.051,
    0.051,
    0.05,
    0.049,
    0.048,
    0.048,
    0.047,
    0.046,
    0.046,
    0.045
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.053,
    0.054,
    0.055,
    0.055,
    0.055,
    0.054,
    0.054,
    0.053,
    0.052,
    0.052,
    0.051,
    0.05,
    0.05,
    0.049,
    0.048,
    0.048,
    0.047,
    0.046,
    0.046,
    0.045,
    0.045,
    0.044,
    0.044,
    0.043,
    0.042,
    0.042
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.047,
    0.048,
    0.048,
    0.048,
    0.048,
    0.048,
    0.048,
    0.047,
    0.047,
    0.047,
    0.046,
    0.046,
    0.045,
    0.045,
    0.044,
    0.044,
    0.043,
    0.043,
    0.043,
    0.042,
    0.042,
    0.041,
    0.041,
    0.041,
    0.04
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.041,
    0.044,
    0.042,
    0.042,
    0.042,
    0.043,
    0.043,
    0.042,
    0.042,
    0.042,
    0.042,
    0.041,
    0.041,
    0.041,
    0.041,
    0.04,
    0.04,
    0.04,
    0.039,
    0.039,
    0.039,
    0.038,
    0.038,
    0.038
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.041,
    0.043,
    0.042,
    0.042,
    0.041,
    0.041,
    0.04,
    0.04,
    0.039,
    0.039,
    0.039,
    0.038,
    0.038,
    0.038,
    0.038,
    0.037,
    0.037,
    0.037,
    0.037,
    0.037,
    0.036,
    0.036,
    0.036
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.04,
    0.04,
    0.04,
    0.039,
    0.039,
    0.038,
    0.038,
    0.037,
    0.037,
    0.037,
    0.036,
    0.036,
    0.036,
    0.035,
    0.035,
    0.035,
    0.035,
    0.035,
    0.035,
    0.034,
    0.034,
    0.034
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.038,
    0.037,
    0.036,
    0.036,
    0.035,
    0.035,
    0.035,
    0.034,
    0.034,
    0.034,
    0.033,
    0.033,
    0.033,
    0.033,
    0.033,
    0.032,
    0.032,
    0.032,
    0.032,
    0.032,
    0.032
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.034,
    0.034,
    0.033,
    0.033,
    0.033,
    0.032,
    0.032,
    0.032,
    0.031,
    0.031,
    0.031,
    0.031,
    0.031,
    0.03,
    0.03,
    0.03,
    0.03,
    0.03,
    0.03,
    0.03
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.032,
    0.031,
    0.031,
    0.03,
    0.03,
    0.03,
    0.03,
    0.029,
    0.029,
    0.029,
    0.029,
    0.029,
    0.028,
    0.028,
    0.028,
    0.028,
    0.028,
    0.028,
    0.028
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.029,
    0.029,
    0.029,
    0.028,
    0.028,
    0.028,
    0.028,
    0.027,
    0.027,
    0.027,
    0.027,
    0.027,
    0.027,
    0.026,
    0.026,
    0.026,
    0.026,
    0.026
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.028,
    0.027,
    0.027,
    0.027,
    0.027,
    0.026,
    0.026,
    0.026,
    0.026,
    0.026,
    0.025,
    0.025,
    0.025,
    0.025,
    0.025,
    0.025,
    0.025
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.026,
    0.026,
    0.025,
    0.025,
    0.025,
    0.025,
    0.025,
    0.024,
    0.024,
    0.024,
    0.024,
    0.024,
    0.024,
    0.024,
    0.024,
    0.023
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.024,
    0.024,
    0.024,
    0.024,
    0.024,
    0.023,
    0.023,
    0.023,
    0.023,
    0.023,
    0.023,
    0.023,
    0.022,
    0.022,
    0.022
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.023,
    0.023,
    0.022,
    0.022,
    0.022,
    0.022,
    0.022,
    0.022,
    0.022,
    0.021,
    0.021,
    0.021,
    0.021,
    0.021
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.02,
    0.021,
    0.021,
    0.021,
    0.021,
    0.021,
    0.02,
    0.02,
    0.02,
    0.02,
    0.02,
    0.02,
    0.02
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.02,
    0.02,
    0.02,
    0.019,
    0.019,
    0.019,
    0.019,
    0.019,
    0.019,
    0.019,
    0.019,
    0.019
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.018,
    0.018,
    0.018,
    0.018,
    0.018,
    0.018,
    0.018,
    0.018,
    0.018,
    0.017,
    0.017
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.017,
    0.017,
    0.017,
    0.017,
    0.017,
    0.017,
    0.017,
    0.017,
    0.017,
    0.017
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.016,
    0.016,
    0.016,
    0.016,
    0.016,
    0.016,
    0.016,
    0.016,
    0.016
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.016,
    0.015,
    0.015,
    0.015,
    0.015,
    0.015,
    0.015,
    0.015
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.015,
    0.015,
    0.014,
    0.014,
    0.014,
    0.014,
    0.014
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.014,
    0.014,
    0.014,
    0.013,
    0.013,
    0.013
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.013,
    0.013,
    0.013,
    0.013,
    0.013
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.013,
    0.013,
    0.013,
    0.013
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.013,
    0.013,
    0.013
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.012,
    0.012
  ],

  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0.012
  ]
];

//const roundToQuarter = number => (Math.floor(number / 0.25) * 0.25).toFixed(2);

const roundToQuarter = value => {
  const inv = 1.0 / 0.25;
  return Math.round(value * inv) / inv;
};

export const calculate = ({ round, entries }) => {
  const payout = round.payout;

  // console.log({ round, entries });

  // const sortByScore = (a, b) => {
  //   a = parseInt($(a).attr("data-score"), 10);

  //   b = parseInt($(b).attr("data-score"), 10);

  //   count += 2;

  //   // compare

  //   if (a > b) {
  //     return 1;
  //   } else if (a < b) {
  //     return -1;
  //   } else {
  //     return 0;
  //   }
  // };

  // var divs =
  //   "MPO,MPM,MPG,FPO,MA1,MA2,MA3,MA4,MA40,MA50,FA1,FA2,FA3,FA40,FA50,MJ18,MJ15,MJ12,MJ10,MJ08,MJ06".split(
  //     ","
  //   );

  // var pay = payouts50;
  const response = [];

  divisions.forEach(d => {
    const e = entries
      .filter(x => x.division === d)
      .sort(sortByProperty("score"));

    // don't calculate division payouts if a score is still 0
    if (e.some(i => i.score === "0" || i.score === 0)) return;

    const totalDivision = e.length;
    const totalPayout = totalDivision * payout;
    const halfDivision = e.length > 1 ? Math.round(e.length * 0.5) : 1;

    let numToPayout = Math.round(e.length * 0.5);

    // figure out how many to pay including if last cash is a tie
    let done = 0;
    if (e.length > 1)
      do {
        if (e[numToPayout] && e[numToPayout - 1].score === e[numToPayout].score)
          numToPayout++;
        else done = 1;
      } while (done === 0);

    const payees = e.filter((x, i) => i < numToPayout);

    payees.forEach((p, i) => {
      const areTies = payees.filter(
        x => x.score === p.score && x.id !== p.id
      ).length;

      if (!areTies) {
        // no ties, just assign payout
        // console.log({ d, areTies, e, numToPayout });
        p.payout = roundToQuarter(payouts50[i][halfDivision - 1] * totalPayout);
      } else {
        const tieCount = areTies + 1;
        const tieStart = payees.findIndex(x => x.score === p.score);

        let payoutToSplit = 0;

        for (let i = 0; i < tieCount; i++) {
          if (tieStart + i < halfDivision)
            payoutToSplit +=
              payouts50[tieStart + i][halfDivision - 1] * totalPayout;
        }
        p.payout = roundToQuarter(payoutToSplit / tieCount);

        // console.log({
        //   d,
        //   payoutToSplit,
        //   i,
        //   areTies,
        //   tieCount,
        //   tieStart
        // });
      }

      //   console.log({ p, i, areTies });
    });

    //    console.log({ payees });
    response.push(...payees);
  });

  return response;
};
