import React from "react";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";

import Aces from "./components/Aces";
import Admin from "./components/admin/Admin";
import Entries from "./components/admin/Entries";
import EntriesScores from "./components/admin/EntriesScores";
import AdminPlayer from "./components/admin/Player";
import AdminRound from "./components/admin/Round";
import AdminRounds from "./components/admin/Rounds";
import RoundMultiEdit from "./components/admin/RoundMultiEdit";
import User from "./components/admin/User";
import Home from "./components/Home";
import Login from "./components/Login";
import Navigation from "./components/Navigation";
import Player from "./components/Player";
import Players from "./components/Players";
import PlayersAll from "./components/PlayersAll";
import Reports from "./components/admin/Reports";
import Round from "./components/Round";
import SiteContext from "./components/SiteContext";
import Transfers from "./components/Transfers";
import useSite from "./components/useSite";
import ProtectedRoute from "./components/ProtectedRoute";

import "./styles/styles.scss";
import "bootstrap/scss/bootstrap.scss";

// Layout component that includes Navigation and renders children
function Layout() {
  return (
    <>
      <Navigation />
      <div>
        <Outlet />
      </div>
    </>
  );
}

// Define the router with all routes
const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />
      },
      {
        path: "/login",
        element: <Login />
      },
      {
        path: "/players",
        element: <Players />
      },
      {
        path: "/players-all",
        element: <PlayersAll />
      },
      {
        path: "/players/:playerId",
        element: <Player />
      },
      {
        path: "/rounds",
        element: <Home />
      },
      {
        path: "/rounds/:roundId",
        element: <Round />
      },
      {
        path: "/aces",
        element: <Aces />
      },
      {
        path: "/transfers",
        element: <Transfers />
      },
      // Admin routes
      {
        path: "/admin",
        element: (
          <ProtectedRoute>
            <Admin />
          </ProtectedRoute>
        ),
        children: [
          {
            path: "reports",
            element: <Reports />
          },
          {
            path: "round/play/:roundId",
            element: <Entries />
          },
          {
            path: "round/scores/:roundId",
            element: <EntriesScores />
          },
          {
            path: "rounds/new",
            element: <AdminRound />
          },
          {
            path: "rounds/multi-edit",
            element: <RoundMultiEdit />
          },
          {
            path: "rounds/:roundId",
            element: <AdminRound />
          },
          {
            path: "rounds",
            element: <AdminRounds />
          },
          {
            path: "player",
            element: <AdminPlayer />
          },
          {
            path: "user",
            element: <User />
          }
        ]
      }
    ]
  }
]);

export default function App() {
  const SiteStateValue = useSite();

  return (
    <SiteContext.Provider value={SiteStateValue}>
      <RouterProvider router={router} />
    </SiteContext.Provider>
  );
}

// function Topics() {
//   let match = useRouteMatch();

//   return (
//     <div>
//       <h2>Topics</h2>

//       <ul>
//         <li>
//           <Link to={`${match.url}/components`}>Components</Link>
//         </li>
//         <li>
//           <Link to={`${match.url}/props-v-state`}>Props v. State</Link>
//         </li>
//       </ul>

//       {/* The Topics page has its own <Switch> with more routes
//           that build on the /topics URL path. You can think of the
//           2nd <Route> here as an "index" page for all topics, or
//           the page that is shown when no topic is selected */}
//       <Switch>
//         <Route path={`${match.path}/:topicId`}>
//           <Topic />
//         </Route>
//         <Route path={match.path}>
//           <h3>Please select a topic.</h3>
//         </Route>
//       </Switch>
//     </div>
//   );
// }

// function Topic() {
//   let { topicId } = useParams();
//   return <h3>Requested topic ID: {topicId}</h3>;
// }
