import React, { useState, useContext, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useApi } from "../../services/useApi";
import SiteContext from "../SiteContext";
import { Button, Form, Alert } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TablePlain as Table } from "../TablePlain";
// import { sortByProperties } from "../helpers";
import "./Rounds.scss";

// Field definitions for round data
const fieldDefinitions = [
  { name: "d", label: "Date", type: "date", required: true },
  { name: "n", label: "Name", type: "text", required: true },
  { name: "loc", label: "Location", type: "text", required: true },
  {
    name: "fee_pdga",
    label: "PDGA Fee",
    type: "text",
    default: ".5",
    required: true
  },
  {
    name: "fee_course",
    label: "Course Fund",
    type: "text",
    default: "0",
    required: true
  },
  {
    name: "fee_other",
    label: "Other Fee",
    type: "text",
    default: "0",
    required: true
  },
  {
    name: "ace",
    label: "Ace Cost",
    type: "text",
    default: "1",
    required: true
  },
  {
    name: "payout",
    label: "Payout Amount",
    type: "text",
    default: "4.5",
    required: true
  },
  {
    name: "pdga_url",
    label: "PDGA Url",
    type: "text",
    default: "",
    required: false
  }
];

const RoundMultiEdit = () => {
  const { token, api } = useContext(SiteContext);
  const navigate = useNavigate();
  const location = useLocation();
  const selectedRoundIds = useMemo(
    () => location.state?.selectedRoundIds || [],
    [location.state?.selectedRoundIds]
  );

  // State for rounds data
  const [rounds, setRounds] = useState([]);
  // State for tracking which rounds have been modified
  const [modifiedRounds, setModifiedRounds] = useState({});
  // State for tracking save status
  const [saveStatus, setSaveStatus] = useState({
    success: false,
    error: false,
    message: ""
  });
  // State for tracking if we're adding a new round
  const [, setAddingNewRound] = useState(false);
  // State to track if rounds have been loaded
  const [roundsLoaded, setRoundsLoaded] = useState(false);

  // API hook for getting rounds
  const { loading: roundsLoading, runApi: roundsApi } = useApi({
    api: api.getRounds
  });

  // Load rounds data on component mount
  useEffect(() => {
    // Only load rounds once when the component mounts or when selectedRoundIds changes
    if (!roundsLoading && !roundsLoaded) {
      if (selectedRoundIds.length > 0) {
        // Load only selected rounds
        roundsApi({ range: "all" })
          .then(data => {
            const filteredRounds = data.filter(round =>
              selectedRoundIds.includes(round.id)
            );
            setRounds(filteredRounds);
            setRoundsLoaded(true);
          })
          .catch(error => {
            console.error("Error loading rounds:", error);
            setRoundsLoaded(true); // Still mark as loaded to prevent infinite loops
          });
      } else {
        // If no rounds are selected, we can leave the rounds array empty
        // User can add new rounds manually
        setRounds([]);
        setRoundsLoaded(true);
      }
    }
  }, [roundsApi, roundsLoading, selectedRoundIds, roundsLoaded]);

  // Handle field change for a specific round
  const handleFieldChange = (roundId, fieldName, value) => {
    // Update rounds data
    setRounds(prevRounds => {
      return prevRounds.map(round => {
        if (round.id === roundId) {
          return { ...round, [fieldName]: value };
        }
        return round;
      });
    });

    // Mark this round as modified
    setModifiedRounds(prev => ({
      ...prev,
      [roundId]: true
    }));
  };

  // Handle date change for a specific round
  const handleDateChange = (roundId, date) => {
    if (date) {
      const timestamp = Math.floor(date.getTime() / 1000);
      handleFieldChange(roundId, "d", timestamp);
    }
  };

  // Save all modified rounds
  const saveModifiedRounds = async () => {
    // Ensure roundsLoaded stays true to prevent API calls
    setRoundsLoaded(true);

    setSaveStatus({ success: false, error: false, message: "" });

    const modifiedRoundIds = Object.keys(modifiedRounds);
    if (modifiedRoundIds.length === 0) {
      setSaveStatus({
        success: true,
        error: false,
        message: "No changes to save."
      });
      return;
    }

    try {
      // Save each modified round
      for (const roundId of modifiedRoundIds) {
        const round = rounds.find(r => r.id === parseInt(roundId));
        if (round) {
          if (round.id < 0) round.id = null;
          await api.saveRound({
            values: round,
            token,
            id: round.id
          });
        }
      }

      setSaveStatus({
        success: true,
        error: false,
        message: `Successfully saved ${modifiedRoundIds.length} round(s).`
      });

      // Clear modified rounds after successful save
      setModifiedRounds({});
    } catch (error) {
      setSaveStatus({
        success: false,
        error: true,
        message: `Error saving rounds: ${error.message}`
      });
    }
  };

  // Add a new empty round
  const addNewRound = () => {
    // Ensure roundsLoaded stays true to prevent API calls
    setRoundsLoaded(true);

    // Create a new round with default values
    const newRound = fieldDefinitions.reduce((obj, field) => {
      obj[field.name] = field.default || "";
      return obj;
    }, {});

    // Set date to today
    newRound.d =
      rounds.length > 0
        ? rounds[rounds.length - 1].d + 7 * 24 * 60 * 60 // Add 1 week in seconds to last round's date
        : Math.floor(new Date().getTime() / 1000); // Default to current time if no rounds exist

    // Add temporary ID (negative to distinguish from server IDs)
    const tempId = -Date.now();
    newRound.id = tempId;

    // Add to rounds list
    setRounds([...rounds, newRound]);

    // Mark as modified
    setModifiedRounds(prev => ({
      ...prev,
      [tempId]: true
    }));

    setAddingNewRound(true);
  };

  // Delete a round
  const deleteRound = roundId => {
    if (window.confirm("Are you sure you want to delete this round?")) {
      // If it's a new unsaved round (negative ID), just remove it from state
      if (roundId < 0) {
        const updatedRounds = rounds.filter(r => r.id !== roundId);
        setRounds(updatedRounds);

        // Remove from modified rounds
        const { [roundId]: _, ...restModified } = modifiedRounds;
        setModifiedRounds(restModified);

        // Redirect if no rounds left
        if (updatedRounds.length === 0) {
          // Reset the roundsLoaded flag
          setRoundsLoaded(true); // Keep it true to prevent another API call

          setSaveStatus({
            success: true,
            error: false,
            message: "All rounds deleted. Redirecting back to rounds list..."
          });
          setTimeout(() => {
            setRoundsLoaded(false); // Reset before navigating
            navigate("/admin/rounds");
          }, 1500);
        }
      } else {
        // Otherwise delete from server
        api.deleteRound({ token, id: roundId }).then(response => {
          if (response?.data?.success) {
            const updatedRounds = rounds.filter(r => r.id !== roundId);
            setRounds(updatedRounds);

            // Remove from modified rounds if present
            if (modifiedRounds[roundId]) {
              const { [roundId]: _, ...restModified } = modifiedRounds;
              setModifiedRounds(restModified);
            }

            // Redirect if no rounds left
            if (updatedRounds.length === 0) {
              // Reset the roundsLoaded flag
              setRoundsLoaded(true); // Keep it true to prevent another API call

              setSaveStatus({
                success: true,
                error: false,
                message:
                  "All rounds deleted. Redirecting back to rounds list..."
              });
              setTimeout(() => {
                setRoundsLoaded(false); // Reset before navigating
                navigate("/admin/rounds");
              }, 1500);
            }
          }
        });
      }
    }
  };

  // Cancel and go back to rounds list
  const onCancel = () => {
    const hasUnsavedChanges = Object.keys(modifiedRounds).length > 0;

    if (hasUnsavedChanges) {
      if (
        window.confirm(
          "You have unsaved changes. Are you sure you want to leave?"
        )
      ) {
        // Reset state before navigating
        setRoundsLoaded(false);
        navigate("/admin/rounds");
      }
    } else {
      // Reset state before navigating
      setRoundsLoaded(false);
      navigate("/admin/rounds");
    }
  };

  return (
    <div className="content" style={{ maxWidth: "100%" }}>
      <h2>Multi-Round Editor</h2>

      {saveStatus.message && (
        <Alert variant={saveStatus.error ? "danger" : "success"}>
          {saveStatus.message}
        </Alert>
      )}

      {selectedRoundIds.length > 0 && !roundsLoading && rounds.length > 0 && (
        <Alert variant="info">
          {rounds.length} round{rounds.length !== 1 ? "s" : ""} loaded from
          selection.
        </Alert>
      )}

      {selectedRoundIds.length > 0 && !roundsLoading && rounds.length === 0 && (
        <Alert variant="warning">
          No rounds found from your selection. They may have been deleted or are
          no longer available.
        </Alert>
      )}

      <div className="mb-3">
        <Button variant="primary" onClick={addNewRound} className="mr-2">
          Add New Round
        </Button>
        <Button
          variant="success"
          onClick={saveModifiedRounds}
          disabled={Object.keys(modifiedRounds).length === 0}
          className="mr-2"
        >
          Save Changes ({Object.keys(modifiedRounds).length})
        </Button>
        <Button variant="secondary" onClick={onCancel}>
          Back to Rounds
        </Button>
      </div>

      {roundsLoading ? (
        <div>Loading rounds...</div>
      ) : (
        <>
          {rounds.length > 0 ? (
            <Table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Name</th>
                  <th>Location</th>
                  <th>PDGA</th>
                  <th>Fund</th>
                  <th>Other</th>
                  <th>Ace</th>
                  <th>Payout</th>
                  <th>PDGA Url</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {rounds.map(round => (
                  <tr
                    key={round.id}
                    className={modifiedRounds[round.id] ? "table-warning" : ""}
                  >
                    <td style={{ maxWidth: "150px" }}>
                      <DatePicker
                        selected={round.d ? new Date(round.d * 1000) : null}
                        onChange={date => handleDateChange(round.id, date)}
                        dateFormat="MM/dd/yyyy"
                        className="form-control"
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        value={round.n || ""}
                        onChange={e =>
                          handleFieldChange(round.id, "n", e.target.value)
                        }
                        required
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        value={round.loc || ""}
                        onChange={e =>
                          handleFieldChange(round.id, "loc", e.target.value)
                        }
                        required
                      />
                    </td>
                    <td style={{ maxWidth: "70px" }}>
                      <Form.Control
                        type="text"
                        value={round.fee_pdga || ""}
                        onChange={e =>
                          handleFieldChange(
                            round.id,
                            "fee_pdga",
                            e.target.value
                          )
                        }
                      />
                    </td>
                    <td style={{ maxWidth: "70px" }}>
                      <Form.Control
                        type="text"
                        value={round.fee_course || ""}
                        onChange={e =>
                          handleFieldChange(
                            round.id,
                            "fee_course",
                            e.target.value
                          )
                        }
                      />
                    </td>
                    <td style={{ maxWidth: "70px" }}>
                      <Form.Control
                        type="text"
                        value={round.fee_other || ""}
                        onChange={e =>
                          handleFieldChange(
                            round.id,
                            "fee_other",
                            e.target.value
                          )
                        }
                      />
                    </td>
                    <td style={{ maxWidth: "70px" }}>
                      <Form.Control
                        type="text"
                        value={round.ace || ""}
                        onChange={e =>
                          handleFieldChange(round.id, "ace", e.target.value)
                        }
                      />
                    </td>
                    <td style={{ maxWidth: "80px" }}>
                      <Form.Control
                        type="text"
                        value={round.payout || ""}
                        onChange={e =>
                          handleFieldChange(round.id, "payout", e.target.value)
                        }
                      />
                    </td>
                    <td style={{ maxWidth: "80px" }}>
                      <Form.Control
                        type="text"
                        value={round.pdga_url || ""}
                        onChange={e =>
                          handleFieldChange(
                            round.id,
                            "pdga_url",
                            e.target.value
                          )
                        }
                      />
                    </td>
                    <td>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => deleteRound(round.id)}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <Alert variant="info">
              No rounds to edit. You can add a new round using the button above
              or return to the rounds list.
            </Alert>
          )}
        </>
      )}
    </div>
  );
};

export default RoundMultiEdit;
