import React, { useState, useEffect, useContext } from "react";
import { useApi } from "../services/useApi";
//import * as api from "../services/api";
import { Button, Row, Col, Modal } from "react-bootstrap";
import { currency, dt } from "./helpers";
import SiteContext from "./SiteContext";
import Table from "./Table";
import FormSetup from "./FormSetup";

const fieldData = [
  { name: "d", label: "Date", type: "date", required: true },
  {
    name: "fromPlayer",
    label: "From Player",
    type: "select",
    default: "0",
    options: []
  },
  {
    name: "toPlayer",
    label: "To Player",
    type: "select",
    default: "0",
    options: []
  },
  { name: "amt", label: "Amount", type: "text", required: true },
  { name: "description", label: "Description", type: "text" }
];

const Transfers = () => {
  const { token, api } = useContext(SiteContext);

  const { loading: transfersLoading, runApi: transfersApi } = useApi({
    api: api.getTransfers
  });

  const { loading: playersLoading, runApi: playerApi } = useApi({
    api: api.getPlayerSimpleList
  });

  const [transfers, setTransfers] = useState();
  const [showModal, setShowModal] = useState();
  const [playerData, setPlayerData] = useState();
  const [players, setPlayers] = useState();

  useEffect(() => {
    if (!transfers && !transfersLoading) {
      transfersApi().then(d => {
        setTransfers(d);
      });

      setTransfers([]);
    }

    if (!playersLoading && !players) {
      playerApi().then(data => {
        setPlayerData(data);
        setPlayers(
          data.map(o => ({
            key: o.id,
            label: `${o.fn} ${o.ln}`,
            value: o.id
          }))
        );
      });
    }
  }, [
    playerApi,
    players,
    playersLoading,
    transfers,
    transfersApi,
    transfersLoading
  ]);

  const onDelete = e => {
    if (e) {
      const c = window.confirm(
        `Are you sure you want to delete transfer of amount $${e.amt}?`
      );
      if (c)
        api.deleteTransfer({ token, id: e.id }).then(response => {
          if (response?.data?.success) {
            setTransfers([...transfers.filter(x => x.id !== e.id)]);
            // getEntries();
          }
        });
    }
  };

  const columns = [
    {
      label: "Date",
      key: row => `${row.id}date`,
      el: row => <>{dt(row.d)}</>
    },
    {
      label: "From",
      key: row => `${row.id}from`,
      el: row => <>{row.fPlayer}</>
    },
    {
      label: "To",
      key: row => `${row.id}to`,
      el: row => <>{row.tPlayer}</>
    },
    {
      label: "Amount",
      key: row => `${row.id}amt`,
      el: row => <>{currency.format(row.amt)}</>
    },
    {
      label: "Description",
      key: row => `${row.id}descript`,
      el: row => <>{row.description}</>
    }
  ];

  if (token)
    columns.push({
      label: "",
      key: row => `${row.id}actions`,
      el: row => (
        <>
          {token && (
            <Button variant="link" onClick={() => onDelete(row)}>
              X
            </Button>
          )}
        </>
      )
    });

  const onSubmit = ({ values }) => {
    api.saveTransfer({ values, token }).then(response => {
      if (response?.data?.success) {
        const fplayer = playerData.find(
          p => p.id === parseInt(values.fromPlayer)
        );
        const tplayer = playerData.find(
          p => p.id === parseInt(values.toPlayer)
        );
        setTransfers([
          {
            ...values,
            fPlayer: fplayer ? `${fplayer?.fn} ${fplayer?.ln}` : "",
            tPlayer: tplayer ? `${tplayer?.fn} ${tplayer?.ln}` : "",
            id: response.data.newId
          },
          ...transfers
        ]);
        setShowModal(false);
      }
    });
  };

  const onCancel = () => {
    setShowModal(false);
  };

  return (
    <div className="content">
      {transfersLoading && "Loading Data..."}
      {transfers && (
        <div>
          <Row>
            <Col></Col>
            <Col className="align-items-right">
              {token && (
                <>
                  <Button
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    Add Transfer
                  </Button>

                  <Modal
                    size="lg"
                    show={showModal}
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Header>
                      <Modal.Title>Add Ace</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                      <FormSetup
                        onSubmit={onSubmit}
                        onCancel={onCancel}
                        fields={fieldData.map(f => ({
                          ...f,
                          options: players || []
                        }))}
                      />
                    </Modal.Body>
                  </Modal>
                </>
              )}
            </Col>
          </Row>

          <hr />
          <h3>Transfers</h3>
          <Table
            columns={columns}
            data={transfers}
            rowKey={row => `row${row.id}`}
          />
        </div>
      )}
    </div>
  );
};

export default Transfers;
