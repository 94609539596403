import "./Entries.scss";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { Button, InputGroup, Form, Row, Col } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import AsyncSelect from "react-select/async";
import axios from "axios";
import merge from "deepmerge";
import { calculate } from "./payouts";
import EditPlayerModal from "../EditPlayerModal";
import { currency, divisions, dt, sortByProperties } from "../helpers";
import { usePathname } from "../hooks/hooks";
import SiteContext from "../SiteContext";
import { useApi } from "../../services/useApi";

// function nextDate(dayIndex) {
//   var today = new Date();
//   today.setDate(
//     today.getDate() + ((dayIndex - 1 - today.getDay() + 7) % 7) + 1
//   );
//   return today;
// }

// const now = new Date();
// const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
// const weekStart =
//   new Date(today.setDate(today.getDate() - today.getDay())).getTime() / 1000;
// const weekEnd = nextDate(6).getTime() / 1000;

const CancelToken = axios.CancelToken;
let cancelPlayerSearching;

const Entries = () => {
  const { token, api } = useContext(SiteContext);

  const [round, setRound] = useState();
  const [aceTotal, setAceTotal] = useState();
  const [entries, setEntries] = useState();
  const [editScore, setEditScore] = useState();
  const [editDivision, setEditDivision] = useState();
  const [editCreditAdded, setEditCreditAdded] = useState();
  const [editCreditUsed, setEditCreditUsed] = useState();
  const [showNew, setShowNew] = useState(false);
  const [selectedPlayerId, setSelectedPlayerId] = useState();
  const [hasCredit, setHasCredit] = useState();

  const { roundId } = useParams();
  const currentUrl = usePathname();

  const { loading: roundLoading, runApi: roundApi } = useApi({
    api: api.getRound
  });

  const { loading: acePotLoading, runApi: aceApi } = useApi({
    api: api.getAcePot
  });

  const { loading: entriesLoading, runApi: entriesApi } = useApi({
    api: api.getEntries
  });

  const getEntries = useCallback(() => {
    entriesApi({ roundId })
      .then(data => setEntries(data))
      .catch(() => setEntries([]));
  }, [entriesApi, roundId]);

  useEffect(() => {
    if (!entries && !entriesLoading) {
      getEntries();
    }
  }, [entries, entriesLoading, getEntries]);

  useEffect(() => {
    if (!aceTotal && !acePotLoading) {
      aceApi()
        .then(data => setAceTotal(data))
        .catch(() => setAceTotal(0));
    }
  }, [aceApi, acePotLoading, aceTotal]);

  useEffect(() => {
    if (!round && !roundLoading && roundId) {
      roundApi({ roundId })
        .then(data => {
          const newData = { ...data };
          setRound(newData);
        })
        .catch(() => setRound(0));
    }
  }, [round, roundApi, roundId, roundLoading]);

  const defaultValues = {
    player: "",
    division: "",
    credit_used: "0.00",
    credit_added: "0.00",
    score: "0",
    payout: "0.00",
    payoutAdjust: "0.00",
    ace: true
  };

  let timer = useRef();

  const loadOptionsWithDebounce = (val, callback) => {
    clearTimeout(timer.current);
    timer.current = setTimeout(async () => {
      const opts = await doSearch(val);
      callback(opts);
    }, 500);
  };

  const [values, setValues] = useState(defaultValues);

  const doSearch = value => {
    if (value.length > 2) {
      if (cancelPlayerSearching) cancelPlayerSearching();

      return api
        .playerSearch(
          { text: value },
          {
            cancelToken: new CancelToken(function executor(c) {
              // An executor function receives a cancel function as a parameter
              cancelPlayerSearching = c;
            })
          }
        )
        .then(d => {
          const opts = d.data
            .filter(p => !entries.some(i => i.player_id === p.id))
            .map(p => ({
              ...p,
              label: `${p.fn} ${p.ln} ($${p?.balance || "0"})`,
              value: p.id
            }));

          const [fn, ln] = value.split(" ");
          return [
            ...opts,
            { fn, ln, id: null, label: `Add player: ${value}`, value: null }
          ];
        })
        .catch(function (thrown) {
          if (axios.isCancel(thrown)) {
            console.log("Request canceled", thrown.message);
          } else {
            alert("error");
          }
        });
    }
  };

  const handleOnChange = e => {
    setValues({ ...values, [e.target.id]: e.target.value });
  };

  const handleOnChangeCheckbox = e => {
    setValues({ ...values, [e.target.id]: !!e?.target?.checked });
  };

  const addNewPlayer = p => {
    api
      .savePlayer({
        values: {
          fn: p.fn,
          ln: p.ln,
          pdga: "",
          division: ""
        },
        token
      })
      .then(results => {
        setValues({
          ...values,
          player: results.newId
        });
      });
  };

  const addPlayer = () => {
    api
      .addEntry({
        values: {
          ...values,
          player_id: values.player.id,
          ace: !!values.ace ? "1" : "0",
          round_id: roundId
        },
        token
      })
      .then(results => {
        if (values.player.division !== values.division) {
          api.savePlayer({
            values: { division: values.division },
            id: values.player.id,
            token
          });
        }

        if (results?.data?.newEntry) {
          setEntries([...entries, results.data.newEntry]);
        } else getEntries();

        resetForm();
      });
  };

  const onDelete = e => {
    if (e) {
      const c = window.confirm(
        `Are you sure you want to delete ${e.fn} ${e.ln}?`
      );
      if (c)
        api.deleteEntry({ token, id: e.id }).then(response => {
          if (response?.data?.success) {
            setEntries([...entries.filter(x => x.id !== e.id)]);
            // getEntries();
          }
        });
    }
  };

  const updateScore = ({ i, entry }) => {
    const score = i.currentTarget.value;

    if (score !== entry.score) {
      // update entry
      api
        .updateEntry({ values: { ...entry, score }, id: entry.id, token })
        .then(r => {
          if (r.data.success) {
            const entryToUpdate = entries.find(e => e.id === entry.id);
            entryToUpdate.score = parseInt(score);

            setEntries(
              [...entries].sort(
                sortByProperties([
                  { field: "division", asc: true },
                  { field: "score", asc: true }
                ])
              )
            );
            setEditScore(null);
          }
        });
    } else {
      setEditScore(null);
    }
  };

  const updateCreditAdded = ({ i, entry }) => {
    const credit_added = i.currentTarget.value;

    if (credit_added !== entry.credit_added) {
      api
        .updateEntry({
          values: { ...entry, credit_added },
          id: entry.id,
          token
        })
        .then(r => {
          if (r.data.success) {
            const entryToUpdate = entries.find(e => e.id === entry.id);
            entryToUpdate.credit_added = parseInt(credit_added);

            setEntries([...entries]);
            setEditCreditAdded(null);
          }
        });
    } else {
      setEditCreditAdded(null);
    }
  };

  const updateCreditUsed = ({ i, entry }) => {
    const credit_used = i.currentTarget.value;

    if (credit_used !== entry.credit_used) {
      api
        .updateEntry({
          values: { ...entry, credit_used },
          id: entry.id,
          token
        })
        .then(r => {
          if (r.data.success) {
            const entryToUpdate = entries.find(e => e.id === entry.id);
            entryToUpdate.credit_used = parseInt(credit_used);

            setEntries([...entries]);
            setEditCreditUsed(null);
          }
        });
    } else {
      setEditCreditUsed(null);
    }
  };

  const updateDivision = ({ i, entry }) => {
    const division = i.currentTarget.value;

    if (division !== entry.division) {
      api
        .updateEntry({
          values: { ...entry, division },
          id: entry.id,
          token
        })
        .then(r => {
          api.savePlayer({
            values: { division },
            id: entry.player_id,
            token
          });

          if (r.data.success) {
            const entryToUpdate = entries.find(e => e.id === entry.id);
            entryToUpdate.division = division;

            setEntries([...entries]);
            setEditDivision(null);
          }
        });
    } else {
      setEditDivision(null);
    }
  };

  const toggleAceFund = ({ entry }) => {
    // update entry
    api
      .updateEntry({
        values: { ...entry, ace: !entry.ace ? 1 : 0 },
        id: entry.id,
        token
      })
      .then(r => {
        if (r.data.success) {
          const entryToUpdate = entries.find(e => e.id === entry.id);
          entryToUpdate.ace = parseInt(!entry.ace ? 1 : 0);

          setEntries(
            [...entries].sort(
              sortByProperties([
                { field: "division", asc: true },
                { field: "score", asc: true }
              ])
            )
          );
          setEditScore(null);
        }
      });
  };

  const resetForm = () => {
    setValues(defaultValues);
    setHasCredit(null);
    setShowNew(!showNew);
  };

  const selectAllText = e => {
    e.target.select();
  };

  const doCalculations = useCallback(() => {
    if (!entries || !round) return null;

    //   if (entries.some(e => e.score === 0)) return null; // do this in the payouts section instead

    const overwriteMerge = (destinationArray, sourceArray, options) =>
      sourceArray;

    const entriesReset = entries.map(e => ({ ...e, payout: 0 }));

    const payees = calculate({
      round,
      entries: entriesReset
    });

    const entriesWithPayouts = merge(payees, entriesReset, {
      arrayMerge: overwriteMerge
    });

    const newEntries = entriesWithPayouts.map(e => ({
      id: e.id,
      payout: e.payout
    }));
    const compareWithOrig = entries.map(e => ({
      id: e.id,
      payout: e.payout
    }));

    // don't update if the payouts haven't changed
    if (JSON.stringify(newEntries) === JSON.stringify(compareWithOrig))
      return null;

    const payload = {
      token,
      entries: newEntries
    };

    api
      .updatePayouts(payload)
      .then(r => {
        setEntries(entriesWithPayouts);
      })
      .catch(e => {
        console.log({ e });
      });
  }, [api, entries, round, token]);

  // update payouts after each update
  useEffect(() => {
    if (entries) doCalculations();
  }, [doCalculations, entries]);

  const nameFieldRef = useRef(null);

  if (!round) return null;

  if (round === 0) return <div>Error loading round</div>;

  return (
    <div className="content">
      <div className="scoresLink">
        <Link to={`/admin/round/scores/${round.id}`}>SCORES</Link>
      </div>
      <div>
        <div>
          <h4>{dt(round.d)}</h4>
          <h6>
            {round?.loc} - {round?.n}
          </h6>
        </div>
        <div className="mt-2 mb-3">
          <b>
            {entries?.length} players | Ace Pot: ${aceTotal}
          </b>
        </div>
        {!showNew && (
          <Button
            onClick={() => {
              setShowNew(!showNew);
              setTimeout(() => {
                if (nameFieldRef.current) {
                  nameFieldRef.current.focus();
                }
              });
            }}
          >
            Add Player
          </Button>
        )}
        {showNew && (
          <div id="FORM">
            <Form noValidate>
              <Row className="pad-2">
                <Form.Group>
                  <Form.Label>Player</Form.Label>
                  <AsyncSelect
                    ref={nameFieldRef}
                    id="player"
                    classNamePrefix="player-search-input"
                    loadOptions={loadOptionsWithDebounce}
                    // loadOptions={e => doSearch(e)}
                    onChange={(o, a) => {
                      if (a.action === "clear") {
                        resetForm(true);
                        return;
                      }

                      if (o?.balance && !isNaN(o?.balance) && o?.balance > 0) {
                        const availCred = o?.balance;
                        const cost =
                          Number(round?.fee_course || "") +
                          Number(round?.fee_other || "") +
                          Number(round?.fee_pdga || "") +
                          Number(round?.payout || "") +
                          Number(round?.ace || "");
                        const cr = availCred > cost ? cost : availCred;
                        setHasCredit(cr);
                      } else {
                        setHasCredit(0);
                      }

                      if (!o) {
                        setValues({ ...values, player: "" });
                        return false;
                      }

                      if (o?.id && o?.fn && o?.division)
                        setValues({
                          ...values,
                          player: o,
                          division: o?.division
                        });
                      else if (!o.id && o?.fn)
                        // add new player record and update form
                        addNewPlayer(o);
                      else
                        setValues({
                          ...values,
                          player: o,
                          division: o?.division || ""
                        });
                    }}
                    value={values.player}
                    hideIndicatorUntilSearch
                    isClearable
                  />
                </Form.Group>
              </Row>
              {values.player && (
                <>
                  <Row className="pad-2">
                    <Form.Group>
                      <Form.Control
                        id="division"
                        as="select"
                        value={values.division}
                        onChange={handleOnChange}
                      >
                        <option>Choose...</option>
                        {divisions.map(d => (
                          <option key={d} value={d}>
                            {d}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Row>

                  <Row className="pad-2">
                    {!!hasCredit &&
                      !Number(values?.credit_added || "0") > 0 && (
                        <>
                          <Col>
                            <Form.Group>
                              <Form.Label>Use Credit:</Form.Label>

                              <InputGroup className="credit-group">
                                <Form.Control
                                  type="number"
                                  id="credit_used"
                                  value={values.credit_used}
                                  onChange={handleOnChange}
                                  onFocus={e => e.target.select()}
                                  aria-describedby="clear-credit-btn"
                                />
                                {Number(values.credit_used) > 0 && (
                                  <Button
                                    id="clear-credit-btn"
                                    className="input-btn"
                                    variant="secondary"
                                    onClick={() => {
                                      setValues({ ...values, credit_used: 0 });
                                    }}
                                  >
                                    Clear
                                  </Button>
                                )}
                              </InputGroup>
                            </Form.Group>
                          </Col>

                          {!Number(values?.credit_used || "0") > 0 && (
                            <Col>
                              <Button
                                className="mt-4 use-credit-button"
                                variant="outline-danger"
                                onClick={() => {
                                  setValues({
                                    ...values,
                                    credit_used: Math.floor(hasCredit)
                                  });
                                }}
                              >
                                Use Credit (${Math.floor(hasCredit)})
                              </Button>
                            </Col>
                          )}
                        </>
                      )}
                  </Row>

                  {!Number(values?.credit_used || "0") > 0 && (
                    <Row className="pad-2">
                      <Col>
                        <Form.Group>
                          <span className="noWrap">
                            Optionally, add to credit:
                          </span>
                          <InputGroup className="credit-group">
                            <Form.Control
                              type="number"
                              id="credit_added"
                              value={values.credit_added}
                              onChange={handleOnChange}
                              onFocus={e => e.target.select()}
                            />
                            {Number(values.credit_added) > 0 && (
                              <Button
                                id="clear-credit-btn"
                                className="input-btn"
                                variant="secondary"
                                onClick={() => {
                                  setValues({ ...values, credit_added: 0 });
                                }}
                              >
                                Clear
                              </Button>
                            )}
                          </InputGroup>
                        </Form.Group>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Form.Group className="checkbox p-4">
                      <Form.Check
                        type="checkbox"
                        id="ace"
                        label="Paid into Ace Fund"
                        onChange={handleOnChangeCheckbox}
                        checked={values?.ace || false}
                      />
                    </Form.Group>
                  </Row>
                  {/* <Form.Group as={Col}>
                  <Form.Label>Score</Form.Label>
                  <Form.Control
                    type="number"
                    id="score"
                    value={values.score}
                    onChange={handleOnChange}
                    onFocus={e => e.target.select()}
                  />
                </Form.Group> */}
                </>
              )}
              <Row className="pad-1">
                <Form.Group>
                  <Button
                    className="ml-auto"
                    onClick={addPlayer}
                    disabled={!values.player || !values.division}
                  >
                    Add Player
                  </Button>
                  <Button className="ml-3" onClick={resetForm} variant="light">
                    Cancel
                  </Button>
                </Form.Group>
              </Row>
            </Form>
          </div>
        )}
        <div id="entries">
          {
            // ENTRIES
            entries &&
              entries.length > 0 &&
              divisions.map((d, i) => {
                const e = entries.filter(e => e.division === d);

                if (e.length === 0) return null;

                return (
                  <div key={d} className="divisonGroup">
                    <h5>
                      {d}
                      {e && e.length && <em> ({e.length} players)</em>}
                    </h5>
                    <table className="entry">
                      {i === 0 && (
                        <thead>
                          <tr>
                            <th></th>
                            <th>Player</th>
                            <th className="division">Div</th>
                            <th className="ace">A</th>
                            <th className="score">Sc</th>
                            <th>PO</th>
                            <th>Cred+</th>
                            <th>Cred-</th>
                            <th></th>
                          </tr>
                        </thead>
                      )}
                      <tbody>
                        {e.map((e, i) => {
                          return (
                            <tr key={e.id}>
                              <td className="pos">{i + 1}</td>
                              <td className="name">
                                <Link
                                  to={currentUrl}
                                  onClick={() =>
                                    setSelectedPlayerId(e.player_id)
                                  }
                                >
                                  {e.fn} {e.ln}
                                </Link>
                                <div className="pdga">{e.pdga}</div>
                              </td>
                              <td className="division">
                                {editDivision?.id !== e.id && (
                                  <span onClick={() => setEditDivision(e)}>
                                    {e.division}
                                  </span>
                                )}
                                {editDivision?.id === e.id && (
                                  <div>
                                    <select
                                      defaultValue={e.division}
                                      onBlur={i =>
                                        updateDivision({ entry: e, i })
                                      }
                                    >
                                      {divisions.map(d => (
                                        <option key={d}>{d}</option>
                                      ))}
                                    </select>
                                  </div>
                                )}
                              </td>

                              <td className="ace">
                                <span
                                  onClick={() => toggleAceFund({ entry: e })}
                                >
                                  {e.ace}
                                </span>
                              </td>
                              <td className="score">
                                {editScore?.id !== e.id && (
                                  <span onClick={() => setEditScore(e)}>
                                    {e.score}
                                  </span>
                                )}
                                {editScore?.id === e.id && (
                                  <div>
                                    <input
                                      style={{ width: "50px" }}
                                      defaultValue={e.score}
                                      autoFocus
                                      onFocus={selectAllText}
                                      type="number"
                                      onBlur={i => updateScore({ entry: e, i })}
                                      onKeyDown={i => {
                                        if (i.key === "Enter") i.target.blur();
                                      }}
                                    />
                                  </div>
                                )}
                              </td>
                              <td className="payout">
                                {currency.format(e.payout)}
                              </td>
                              <td className="credit-added">
                                {editCreditAdded?.id !== e.id && (
                                  <span onClick={() => setEditCreditAdded(e)}>
                                    {currency.format(e.credit_added)}
                                  </span>
                                )}
                                {editCreditAdded?.id === e.id && (
                                  <div>
                                    <input
                                      style={{ width: "50px" }}
                                      defaultValue={e.credit_added}
                                      autoFocus
                                      onFocus={selectAllText}
                                      type="number"
                                      onBlur={i =>
                                        updateCreditAdded({ entry: e, i })
                                      }
                                      onKeyDown={i => {
                                        if (i.key === "Enter") i.target.blur();
                                      }}
                                    />
                                  </div>
                                )}
                              </td>
                              <td className="credit-used">
                                {editCreditUsed?.id !== e.id && (
                                  <span onClick={() => setEditCreditUsed(e)}>
                                    {currency.format(e.credit_used)}
                                  </span>
                                )}
                                {editCreditUsed?.id === e.id && (
                                  <div>
                                    <input
                                      style={{ width: "50px" }}
                                      defaultValue={e.credit_used}
                                      autoFocus
                                      onFocus={selectAllText}
                                      type="number"
                                      onBlur={i =>
                                        updateCreditUsed({ entry: e, i })
                                      }
                                      onKeyDown={i => {
                                        if (i.key === "Enter") i.target.blur();
                                      }}
                                    />
                                  </div>
                                )}
                              </td>
                              <td className="delete">
                                <Button
                                  variant="link"
                                  onClick={() => onDelete(e)}
                                >
                                  X
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                );
              })
          }
        </div>
      </div>

      <div className="calculate">
        <br />
        <br />
        <br />
        <div>
          <p>
            {entries?.length} players tonight at {round?.loc}
          </p>
          <p>
            Payouts:{" "}
            <a href={`http://league.proflightdg.com/rounds/${round.id}`}>
              http://league.proflightdg.com/rounds/{round.id}
            </a>
          </p>
          {round?.pdga_url && (
            <p>
              PDGA Results: <a href={round?.pdga_url}>{round?.pdga_url}</a>
            </p>
          )}
          <b>Here are the division winners :</b>
          <div>
            {entries &&
              entries.length > 0 &&
              !entries.some(e => e.score === 0) &&
              divisions.map(d => {
                const ents = entries.filter(e => e.division === d);
                if (ents.length === 0) return null;

                const winningScore = ents[0].score;

                return (
                  <div key={d}>
                    <div>
                      {d} ({ents.length} players) :
                      {ents
                        .filter(e => e.score === winningScore)
                        .map((e, i) => (
                          <span key={e.fn}>
                            {i > 0 && " & "} {e.fn} {e.ln}
                          </span>
                        ))}
                      <span> ({winningScore})</span>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <br />
        <br />
        {entries && entries.length > 0 && (
          <Button size="sm" variant="light" onClick={() => doCalculations()}>
            Re-Calculate Payouts
          </Button>
        )}
      </div>
      {selectedPlayerId && (
        <EditPlayerModal
          playerId={selectedPlayerId}
          onCancel={() => setSelectedPlayerId(null)}
          onComplete={({ values, playerId }) => {
            const newEntries = entries.map(e => {
              if (e.player_id === playerId) return { ...e, ...values };
              else return e;
            });
            setEntries(newEntries);
            setSelectedPlayerId(null);
          }}
        />
      )}
    </div>
  );
};

export default Entries;
