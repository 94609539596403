import React, { useContext, useRef } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import SiteContext from "./SiteContext";

const Navigation = () => {
  const { token, clearToken } = useContext(SiteContext);
  const navbarToggleRef = useRef(null);

  const handleNavClick = () => {
    if (navbarToggleRef.current && navbarToggleRef.current.click) {
      navbarToggleRef.current.click();
    }
  };

  return (
    <Navbar
      collapseOnSelect
      expand="sm"
      bg={!token ? "dark" : "info"}
      variant="dark"
      className="pl-2"
    >
      <Navbar.Brand as={Link} to={!token ? "/" : "/admin/rounds"}>
        ProFlight PDGA League
      </Navbar.Brand>
      <Navbar.Toggle
        ref={navbarToggleRef}
        aria-controls="responsive-navbar-nav"
      />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          {!token ? (
            <>
              <Nav.Link as={Link} to="/" onClick={handleNavClick}>
                Rounds
              </Nav.Link>
              <Nav.Link as={Link} to="/players" onClick={handleNavClick}>
                Players
              </Nav.Link>
            </>
          ) : (
            <>
              <Nav.Link as={Link} to="/admin/rounds" onClick={handleNavClick}>
                Rounds
              </Nav.Link>
              <Nav.Link as={Link} to="/players" onClick={handleNavClick}>
                Players
              </Nav.Link>
              <Nav.Link as={Link} to="/transfers" onClick={handleNavClick}>
                Transfers
              </Nav.Link>
              <Nav.Link as={Link} to="/admin/reports" onClick={handleNavClick}>
                Reports
              </Nav.Link>
            </>
          )}
          <Nav.Link as={Link} to="/aces" onClick={handleNavClick}>
            Aces
          </Nav.Link>

          {/* {(
          <NavDropdown title="Admin" id="collasible-nav-dropdown">
            <NavDropdown.Item to="/admin/rounds">Rounds</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item to="/admin/Players">Players</NavDropdown.Item>
          </NavDropdown>
        )} */}
        </Nav>
        <Nav>
          {!token && (
            <Nav.Link as={Link} to="/admin" onClick={handleNavClick}>
              Admin
            </Nav.Link>
          )}
          {token && (
            <Nav.Link
              className="push-right"
              onClick={() => {
                handleNavClick();
                clearToken();
              }}
            >
              LogOut
            </Nav.Link>
          )}
        </Nav>
        {/* <Nav>
  <Nav.Link href="#deets">More deets</Nav.Link>
  <Nav.Link eventKey={2} href="#memes">
    Dank memes
  </Nav.Link>
</Nav> */}
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Navigation;
